import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { Configuration } from '../models/configuration';
import { map } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class ConfigurationService {

  // initialized by app module APP_FACTORY
  conf: Configuration = null;

  constructor(private http: HttpClient) {
  }

  getConfiguration(): Observable<Configuration> {
    if (this.conf == null) {
      const confVersion = Date.now().toString(); // avoid caching
      return this.http.get(`/assets/conf.json?_version=${confVersion}`).pipe(map(response => {
        this.conf = <Configuration>response;
        return this.conf;
      }));
    } else {
      return of(this.conf);
    }
  }

  setCookie(name: string, value: string, expireDays: number, path: string = '/'): void {
    const d: Date = new Date();
    d.setTime(d.getTime() + expireDays * 24 * 60 * 60 * 1000);
    const expires = `expires=${d.toUTCString()}`;
    const cpath: string = path ? `; path=${path}` : '';
    document.cookie = `${name}=${value}; ${expires}${cpath}`;
  }

  getCookie(name: string): string {
    const value = '; ' + document.cookie;
    const parts = value.split('; ' + name + '=');
    if (parts.length === 2) {
      return parts.pop().split(';').shift();
    } else {
      return null;
    }
  }

  removeCookie(name: string): void {
    this.setCookie(name, '', 0);
  }

}
