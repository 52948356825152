import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-inventories-list',
  templateUrl: './inventories-list.component.html',
  styleUrls: ['./inventories-list.component.scss']
})
export class InventoriesListComponent implements OnInit {

  inventories : any[]
  constructor() { }

  ngOnInit() {
  }

  onSort(event: any) {

  }
}