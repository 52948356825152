import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tenant-type-selector',
  templateUrl: './tenant-type-selector-component.component.html',
  styleUrls: ['./tenant-type-selector-component.component.scss']
})
export class TenantTypeSelectorComponentComponent implements OnInit {

  @Input()
  control: FormControl

  constructor() { }

  ngOnInit() {
  }

  onSelect(choice: string ){
    if(this.control){

      // if deselection
      if(this.control.value == choice){
        this.control.setValue(null)
      }else{
        this.control.setValue(choice)
      }
    
    }
  }

  isActive(value: string ){
    if(this.control){
      return this.control.value === value
    }
    return false
  }
}
