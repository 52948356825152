import {ApiService, GetOpts} from './api.service';
import {Observable, Subject} from 'rxjs';
import {Injectable} from '@angular/core';
import {Tenancy, TenancyCriteria} from '../../tenancies/model'


@Injectable()
export class TenancyService {

  private emitChangeSource = new Subject<any>();
  
  constructor(private api: ApiService) {}

  emitChange(change: any) {
    this.emitChangeSource.next(change);
  }

  searchTenancies(criteria: TenancyCriteria): Observable<Tenancy[]> {

    var params : string[] = []

    if(criteria.propertyId){
      params.push("propertyId="+criteria.propertyId)
    }

    if(criteria.active){
      params.push("active="+criteria.active)
    }

    return this.api.getJson(`${this.api.url}/tenancies?${params.join("&")}`, {defaultIfNull: []});
  }

  getTenancy(name: string, opts:GetOpts = {}): Observable<Tenancy> {
    return this.api.getJson(`${this.api.url}/tenancies/${name}`, opts);
  }

  updateTenancy(tenancy: Tenancy): Observable<Tenancy> {
    return this.api.putJson(`${this.api.url}/tenancies/${tenancy.id}`, tenancy);
  }

  saveTenancy(tenancy: Tenancy): Observable<Tenancy> {
    return this.api.postJson(`${this.api.url}/tenancies`, tenancy);
  }

  deleteTenancy(name: string): Observable<void> {
    return this.api.delete(`${this.api.url}/tenancies/${name}`);
  }


}
