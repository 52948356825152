import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';


@Component({
  selector: 'app-property-additional-tab',
  templateUrl: './property-additional-tab.component.html',
  styleUrls: ['./property-additional-tab.component.scss']
})
export class PropertyAdditionalTabComponent implements OnInit {

  constructor() { }

  @Input()
  form: FormGroup
  
  @Input()
  formSubmited: boolean

  ngOnInit(): void {
  }

}
