import { Ticket } from '../../financies/tickets/model'
import * as moment from 'moment'

export class TicketHelper{
   
    // Provide A PDF file name for the given invoice
    static  getTicketName(ticket: Ticket): string {
        var res = "ticket.pdf"
    
        if(ticket) {
          res = "ticket"
    
          // Add ticket property reference in name
          if (ticket.property && ticket.property.reference) {
            res += "-" + ticket.property.reference
          }
      
          // Add date to the ticket
          if (ticket.period && ticket.period.month && ticket.period.year) {
            
            let strYear = (""+ticket.period.year).padStart(2, "0")
            let strMonth = (""+ticket.period.month).padStart(2, "0")

            res += "-"+ strMonth +"-"+ strYear
          }
          res += ".pdf"
        }
        return res
    }
      
}