import { Injectable, EventEmitter } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { flatMap, map, catchError } from 'rxjs/operators';
import { ConfigurationService } from './configuration.service';
import { NO_CACHE_HEADERS, reqOpts } from '../api';
import { User, AuthResponse } from '../models/user';
import { Router } from '@angular/router';


export const JSON_HEADERS = {
  "Content-Type": "application/json; charset=utf-8",
  "Accept": "application/json; charset=utf-8"
};

export const AUTH_COOKIE = 'jwt-token';


@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {


  jwtToken: string = null;

  bus: EventEmitter<boolean> = new EventEmitter();

  user: User = null

  public redirectUrl = '/';
  

  constructor(
      private configurationService: ConfigurationService,
      private http: HttpClient,
      private router: Router) {
    this.jwtToken = this.configurationService.getCookie(AUTH_COOKIE);
  }

  headers(...headers: { [name: string]: string }[]): HttpHeaders {
    const all_headers = headers.slice();
    if (this.jwtToken) {
      all_headers.push({
        'Authorization': 'Bearer ' + this.jwtToken
      });
    }
    all_headers.push(NO_CACHE_HEADERS);
    return reqOpts(...all_headers);
  }

  isLoggedIn(): boolean {
    return this.jwtToken != null;
  }

  resetToken(): void {
    this.jwtToken = null;
    this.user = null;
    this.bus.emit(false);
    this.router.navigate(["signin"]);
    console.log("navigate")
  }

  authenticate(login, password): Observable<AuthResponse> {
    return this.configurationService
      .getConfiguration()
      .pipe(
        flatMap( config => {  return this.authenticate$(config.apiUrl, login, password); })
      )
  }

  getUser(): User{
    return this.user
  }


  authenticate$(apiUrl: string, login: string, password: string): Observable<AuthResponse>{

    return this.http
    .post(
      `${apiUrl}/auth/session`,
      {
        'login': login,
        'password': password
      },
      { headers: reqOpts(NO_CACHE_HEADERS, JSON_HEADERS) }
    )
    .pipe(
      map(response => {
        this.jwtToken = response['access_token'];
        this.configurationService.setCookie(AUTH_COOKIE, this.jwtToken, 1);

        let authResponse: AuthResponse =  <AuthResponse> response
       
        if(authResponse){
          this.user = authResponse.user
        }
        return authResponse;
      }),
      catchError(error => {
        if (error && error.status === 401) {
          return of(null);
        } 
        return throwError(error || 'Server error');
      })
    );
  }

  logout(): void {
    this.configurationService.removeCookie(AUTH_COOKIE);
    document.location.reload();
  }

}
