import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-property-general-tab',
  templateUrl: './property-general-tab.component.html',
  styleUrls: ['./property-general-tab.component.scss']
})
export class PropertyGeneralTabComponent implements OnInit {

  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean

  ngOnInit(): void {
  }

}

