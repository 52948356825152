import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-side-window',
  templateUrl: './side-window.component.html',
  styleUrls: ['./side-window.component.scss']
})
export class SideWindowComponent implements OnInit {

  _show: boolean = false

  @Input()
  set show(show : boolean){
    if(show != undefined){
      this._show = show
    }
  }

  get show(): boolean{
    return this._show 
  }

  @Output()
  statusChange: EventEmitter<boolean> = new EventEmitter();

  constructor() { }

  ngOnInit() {
  }

  close(){
    this.show = false;
    this.statusChange.emit(this.show)
  }
}
