import { Component, OnInit } from '@angular/core';
import { PropertyService } from 'src/app/core/services/property.service';
import { Property } from '../model';


@Component({
  selector: 'app-properties-list',
  templateUrl: './properties-list.component.html',
  styleUrls: ['./properties-list.component.scss', './../../../../styles/divider.scss']
})
export class PropertiesListComponent implements OnInit {

  properties : Property[]

  property: Property

  constructor(
    private propertyService: PropertyService,
  ) {}

  ngOnInit() {
    this.reload()
  }


  reload(){
    this.property = null
    this.propertyService.searchProperties({})
      .subscribe( properties => {
        this.properties = properties

        if( this.properties.length > 0){
          this.property = this.properties[0]
        }
      })
  }

  isActive(property: Property){
    if(this.property){
      return this.property.id == property.id
    }
    return false;
  }

  onSelect(property: Property){
    this.property = property
  }

  onDelete(id: string){
    this.reload()
  }
}
