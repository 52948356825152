export class User {
    id?: string
    firstName: string
    lastName: string
    email?: string
    avatar?: string
    phone?:string
    birthDate?: Date
    address?: Address
}

export class Address{
    line1?: string
    line2?: string
    zipCode?: string
    city?: string
    country?: string
}


export class UserCriteria{

}

export class AuthResponse {

    access_token: string
    token_type: string
    expires_in: number
    user: User

}