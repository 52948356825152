import { Component, OnInit, Input } from '@angular/core';
import { TenantInfo } from 'src/app/financies/invoices/model';

@Component({
  selector: 'app-tenants-info-avatars-viewer',
  templateUrl: './tenants-info-avatars-viewer.component.html',
  styleUrls: ['./tenants-info-avatars-viewer.component.scss']
})
export class TenantsInfoAvatarsViewerComponent implements OnInit {

  constructor() { }

  @Input()
  tenantsInfo: TenantInfo[];

  ngOnInit() {
  }

  getTenantInitials(tenant: TenantInfo) : string {

    if(!tenant || !tenant.firstName || !tenant.lastName ){
      return "??"
    }
    return (tenant.firstName[0] + tenant.lastName[0]).toUpperCase()
  }

}
