import { Component, Input } from '@angular/core';
import { AbstractControlDirective, AbstractControl } from '@angular/forms';

@Component({
 selector: 'show-errors',
 template: `

   <ul *ngIf="shouldShowErrors()" class="alert alert-danger">
     <li  *ngFor="let error of listOfErrors()">{{error}}</li>
   </ul>
 `,
 styles: ['ul { list-style-type: none; }']
})
export class ShowErrorsComponent {

 private static readonly errorMessages = {
   'required': (name) => name +' is required',
   'minlength': (name, params) => 'The min number of characters is ' + params.requiredLength,
   'maxlength': (name, params) => 'The max allowed number of characters is ' + params.requiredLength,
   'pattern': (name, params) => name + ' pattern is: ' + params.requiredPattern,
 };

 @Input()
 private control: AbstractControlDirective | AbstractControl;

 @Input()
 private submited: boolean = false;

 @Input()
 private name: string = null;

 shouldShowErrors(): boolean {
   // console.log(this.control)
   return this.control &&
     this.control.errors &&
     (this.control.dirty || this.control.touched || this.submited);
 }

 listOfErrors(): string[] {
   return Object.keys(this.control.errors)
     .map(field => this.getMessage(field, this.control.errors[field]));
 }

 private getMessage(type: string, params: any) {
   let fnc =  ShowErrorsComponent.errorMessages[type]
   if(fnc){
    return fnc(this.name, params);
   }
 }

}
