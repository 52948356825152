import {ApiService, GetOpts} from './api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Tenant, TenantCriteria} from '../../tenants/model'

@Injectable()
export class TenantService {

  constructor(private api: ApiService) {}

  searchTenants(criteria: TenantCriteria): Observable<Tenant[]> {
    return this.api.getJson(`${this.api.url}/tenants`, {defaultIfNull:[]});
  }

  getTenant(name: string, opts:GetOpts = {}): Observable<Tenant> {
    return this.api.getJson(`${this.api.url}/tenants/${name}`, opts);
  }

  updateTenant(tenant: Tenant): Observable<Tenant> {
    return this.api.putJson(`${this.api.url}/tenants/${tenant.id}`, tenant);
  }

  saveTenant(tenant: Tenant): Observable<Tenant> {
    return this.api.postJson(`${this.api.url}/tenants`, tenant);
  }

  deleteTenant(name: string): Observable<void> {
    return this.api.delete(`${this.api.url}/tenants/${name}`);
  }
}
