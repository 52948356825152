import { Injectable } from '@angular/core';
import { ApiService, GetOpts } from './api.service';
import { Observable } from 'rxjs';
import { Invoice, InvoiceCriteria } from 'src/app/financies/invoices/model';
import { Ticket } from 'src/app/financies/tickets/model';


@Injectable({
  providedIn: 'root'
})
export class InvoiceService {

  constructor(private api: ApiService) {}

  search(criteria: InvoiceCriteria): Observable<Invoice[]> {

    var params : string[] = []

    if(criteria.ticketIds){
      params.push("invoicesIds="+criteria.ticketIds.join(","))
    }

    if(criteria.tenantId){
      params.push("tenantId="+criteria.tenantId)
    }

    if(criteria.tenancyId){
      params.push("tenancyId="+criteria.tenancyId)
    }

    if(criteria.status && criteria.status.length > 0){
      params.push("status="+criteria.status.join(","))
    }

    if(criteria.propertyId){
      params.push("propertyId="+criteria.propertyId)
    }

    return this.api.getJson(`${this.api.url}/invoices?${params.join("&")}`, {allow404: true, defaultIfNull:[] });
  }

  getInvoice(id: string, opts: GetOpts = {}): Observable<Invoice> {
    return this.api.getJson(`${this.api.url}/invoices/${id}`, opts);
  }

  updateInvoice(invoice: Invoice): Observable<Invoice> {
    return this.api.putJson(`${this.api.url}/invoices/${invoice.id}`, invoice);
  }

  saveInvoice(invoice: Invoice): Observable<Invoice> {
    return this.api.postJson(`${this.api.url}/invoices`, invoice);
  }

  deleteInvoice(id: string): Observable<void> {
    return this.api.delete(`${this.api.url}/invoices/${id}`);
  }

  getTicket(id: string, initMissing: boolean, opts: GetOpts = {}): Observable<Ticket> {
    return this.api.getJson( `${this.api.url}/invoices/${id}/ticket?initMissing=${initMissing}`, opts)
  }
  

  downloadPDFTicket(id: string): Observable<any> {
    return this.api.downloadFile( `${this.api.url}/invoices/${id}/ticket/_pdf`)
  }

  generateTenancyInvoices(tenancyId: string): Observable<any> {
    return this.api.downloadFile( `${this.api.url}/invoices/-/_generate?tenancyId=${tenancyId}`)
  }
}
