import { Injectable } from '@angular/core';
import { ApiService, GetOpts } from './api.service';
import { Observable } from 'rxjs';
import { Transaction, TransactionCriteria, TransactionBucket, TransactionBuckets, Transactions } from 'src/app/financies/transactions/model';


@Injectable({
  providedIn: 'root'
})
export class TransactionService {

  constructor(private api: ApiService) {}

  search(criteria: TransactionCriteria): Observable<Transactions> {

    var params : string[] = []

    if(criteria.propertyId){
      params.push("propertyId="+criteria.propertyId)
    }
    if(criteria.tags){
      params.push("tags="+criteria.tags.join(","))
    }

    return this.api.getJson(`${this.api.url}/transactions?${params.join("&")}`, {allow404: true, defaultIfNull:[] });
  }

  getTransaction(id: string, opts: GetOpts = {}): Observable<Transaction> {
    return this.api.getJson(`${this.api.url}/transactions/${id}`, opts);
  }

  updateTransaction(transaction: Transaction): Observable<Transaction> {
    return this.api.putJson(`${this.api.url}/transactions/${transaction.id}`, transaction);
  }

  saveTransaction(transaction: Transaction): Observable<Transaction> {
    return this.api.postJson(`${this.api.url}/transactions`, transaction);
  }

  deleteTransaction(id: string): Observable<void> {
    return this.api.delete(`${this.api.url}/transactions/${id}`);
  }


  statsByMonth(): Observable<TransactionBuckets>{
    return this.api.getJson(`${this.api.url}/transactions_stats/month`, {allow404: true, defaultIfNull:[] })
  }

}
