import { User } from './user'

export class AccountCreation {
    login: string
    password: string
    user: User
}

export class AuthRequest {
    login: string
    password: string
}


export class AuthResponse {

}