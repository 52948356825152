import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { ApiService } from 'src/app/core/services/api.service';
import { Contract } from '../../../model';

@Component({
  selector: 'app-add-contrat-modal',
  templateUrl: './add-contrat-modal.component.html',
  styleUrls: ['./add-contrat-modal.component.scss']
})
export class AddContratModalComponent implements OnInit {

  form: FormGroup

  fileToUpload: File = null;
  
  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService) { }

  ngOnInit() {
    this.initForm()
  }

  initForm(){
    this.form = new FormGroup({
      'type': new FormControl(null, []),
      'file': new FormControl(null, []),
      'description': new FormControl(null, []),
      'dateOfIssue': new FormControl(null, []),
      'expiryDate': new FormControl(null, [])
    })
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  uploadFileToActivity() {
    this.api.uploadFile(`${this.api.url}/documents`,  this.fileToUpload)
      .subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  onSubmit(){
    let contract: Contract = Object.assign({}, this.form.value);
    this.activeModal.close(contract)
  }

  abort(){
    this.activeModal.dismiss()
  }

}
