import { Component, OnInit } from '@angular/core';
import { TenancyService } from 'src/app/core/services/tenancie.service';
import { TenancyCriteria, Tenancy } from '../model';

@Component({
  selector: 'app-tenancies-list',
  templateUrl: './tenancies-list.component.html',
  styleUrls: ['./tenancies-list.component.scss', './../../../styles/divider.scss']
})
export class TenanciesListComponent implements OnInit {
  
  tenancies : Tenancy[]
  
  tenancy: Tenancy


  constructor(
    private tenancyService: TenancyService,
  ) {}

  ngOnInit() {
    this.reload()
  }


  reload(){
    this.tenancy = null
    this.tenancyService.searchTenancies({})
      .subscribe( tenancies => {
        this.tenancies = tenancies

        if( this.tenancies.length > 0){
          this.tenancy = this.tenancies[0]
        }
      })
  }

  isActive(tenancy: Tenancy){
    if(this.tenancy){
      return this.tenancy.id == tenancy.id
    }
    return false;
  }

  onSelect(tenancy: Tenancy){
    this.tenancy = tenancy
  }

  onDelete(id: string){
    this.reload()
  }
}