import { Component, OnInit, Input } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
  selector: 'app-tag-component',
  templateUrl: './tag-component.component.html',
  styleUrls: ['./tag-component.component.scss']
})
export class TagComponentComponent implements OnInit {

  @Input()
  control: FormControl

  @Input()
  name: string

  @Input()
  theme: string =""

  constructor() { }

  ngOnInit() {
  }

  onClick(){
    this.control.setValue(!this.control.value)
  }

  isActive(): boolean{
    if (this.control == null){
      return false
    }
    return this.control.value === true
  }

}
