import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { Invoice } from '../model';
import { FormGroup, FormControl } from '@angular/forms';
import FormHelper from 'src/app/core/helper/form';
import { ToastService } from 'src/app/core/services/toast.service';
import { Observable } from 'rxjs';


@Component({
  selector: 'app-invoice-edit',
  templateUrl: './invoice-edit.component.html',
  styleUrls: ['./invoice-edit.component.scss']
})
export class InvoiceEditComponent implements OnInit {

  // global component status
  status: string = "LOADING"
  formSubmited: boolean = false
  validationError: string = null 

  public statuses :string[] = ["paid", "unpaid", "partial", "overdue"]

  @Output()
  private onChange: EventEmitter<boolean>  = new EventEmitter<boolean>()

  private _invoiceId: string

  protected invoice: Invoice

  protected form: FormGroup

  constructor(
    private invoiceService: InvoiceService,
    private toastService: ToastService,
  ) { }

  // Invoice identifier
  @Input()
  set invoiceId(invoiceId: string){
    this._invoiceId = invoiceId
    this.load()
  }

  get invoiceId(): string{ 
    return this._invoiceId
  }

  ngOnInit() {
  }

  onSubmit(){

    this.formSubmited = true

		if (this.form.valid) {

			let invoice = this.formToObject(this.invoice)

			let save$ : Observable<Invoice>
			if (this.invoice.id) {
				save$ = this.invoiceService.updateInvoice(invoice)
			} else {
				save$ = this.invoiceService.saveInvoice(invoice)
			}

			save$.subscribe(
				_ => { this.onChange.emit(true) },
				error => { this.toastService.show('A error occured while saving invoice', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
			console.log(this.form)
			console.log("Invalid form");
		}
  }
  
  cancel(){
    this.onChange.emit(false)
    return false
  }

  load(){
    this.initForm()

    this.invoiceService
      .getInvoice(this._invoiceId, { allow404: true })
      .subscribe(invoice => {
        this.invoice = invoice
        this.objectToForm(this.invoice)
        this.status = "LOADED"
      }, err => {
        console.log(err)
        this.status = "ERROR"
      })
  }

  objectToForm(invoice: Invoice ): void  {
		var formPatch = JSON.parse(JSON.stringify(invoice));
		this.form.patchValue(formPatch)
	}


  // Convert the invoice form to objet
  formToObject(invoice: Invoice): Invoice {

    if(!invoice) {
      invoice = <Invoice>{}
    }
		let patchedInvoice: Invoice = Object.assign(invoice, this.form.value);

		patchedInvoice.rentExcludingCharges =  FormHelper.getFloatValue( this.form, "rentExcludingCharges")
		patchedInvoice.charges =  FormHelper.getFloatValue( this.form, "charges")
		patchedInvoice.rent =  FormHelper.getFloatValue( this.form, "rent")

		patchedInvoice.emittedAt = FormHelper.getDateValue(this.form, "emittedAt")
    patchedInvoice.paidAt = FormHelper.getDateValue(this.form, "paidAt")
    console.log(patchedInvoice.paidAt)
    
		patchedInvoice.id = this._invoiceId
	
		return patchedInvoice
	}

  // initialize form field
  initForm(){

    this.form  =  new FormGroup({ 
      'id': new FormControl('', [ ]) ,
      'status': new FormControl('', []),

      'date': new FormControl('', [ ]) ,
      'startDate': new FormControl('', [ ]) ,
      'endDate': new FormControl('', [ ]) ,


      'rent': new FormControl({ value:'', disabled:true }, [ ]) ,
      'charges': new FormControl('', [ ]) ,
      'rentExcludingCharges': new FormControl('', [ ]) ,

      'desc':  new FormControl('', [ ]) ,

      'year':  new FormControl('', [ ]) ,
      'month':  new FormControl('', [ ]) ,

      //'paidAt': new FormControl('', [ ]) ,
     //'paymentType':  new FormControl('', [ ]) ,

      'emittedAt': new FormControl('', [ ]) ,

      'signatories':  new FormGroup({ 
        'note': new FormControl('', [ ])
      }) 
    })

    this.addFormListeners()
  }


  //add event listener to form 
  addFormListeners(){

    this.form.get('rentExcludingCharges').valueChanges.subscribe(val => {
       this.actualiseRent()
    });

    this.form.get('charges').valueChanges.subscribe(val => {
      this.actualiseRent()
   });

   this.form.get('status').valueChanges.subscribe(val => {

      if( val === 'paid'){
        this.form.addControl('paidAt',  new FormControl('', [ ]) )
        this.form.addControl('paymentType',  new FormControl('', [ ]) )
      }else{
        this.form.removeControl('paidAt')
        this.form.removeControl('paymentType')
      }

   })
  }


  actualiseRent(){
    let rent = 0

    let rentExcludingCharges =  Number(this.form.get('rentExcludingCharges').value)
    if( rentExcludingCharges != NaN) {
      rent += rentExcludingCharges
    }

    let charges = Number(this.form.get('charges').value)
    if( charges != NaN) {
      rent += charges
    }
    this.form.get('rent').setValue(rent.toFixed(2))
  }
}

