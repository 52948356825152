import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-add-document-modal',
  templateUrl: './add-document-modal.component.html',
  styleUrls: ['./add-document-modal.component.scss']
})
export class AddDocumentModalComponent implements OnInit {

  form: FormGroup

  fileToUpload: File = null;
  
  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService) { }


  ngOnInit() {
    this.initForm()
  }

  initForm(){
    this.form =  new FormGroup({
        'type': new FormControl(null, []),
        'sharing': new FormControl(null, []),
        'description': new FormControl(null, []),
        'file': new FormControl(null, [])
    })
  }

  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  uploadFileToActivity() {
    this.api.uploadFile(`${this.api.url}/documents`,  this.fileToUpload)
      .subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }
}
