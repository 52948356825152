import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-property-type-icon',
  templateUrl: './property-type-icon.component.html',
  styleUrls: ['./property-type-icon.component.scss']
})
export class PropertyTypeIconComponent implements OnInit {

  constructor() { }

  @Input()
  public type: string
  
  ngOnInit() {
  }

}
