import { Component, OnInit, Input } from '@angular/core';
import { Property } from '../../model';

@Component({
  selector: 'app-property-line',
  templateUrl: './property-line.component.html',
  styleUrls: ['./property-line.component.scss']
})
export class PropertyLineComponent implements OnInit {

  @Input()
  property: Property

  @Input()
  active: Boolean

  constructor() { }

  ngOnInit() {
  }

  isActive(): Boolean{
    return this.active;
  }
}
