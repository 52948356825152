import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot,RouterStateSnapshot, UrlTree } from '@angular/router';
import { AuthenticationService } from './authentication.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
  
      constructor(
          private router: Router, 
          private authService: AuthenticationService ) {
      }
  
      canActivate(route: ActivatedRouteSnapshot,
                  state: RouterStateSnapshot): boolean|UrlTree {
  
        if (!this.authService.isLoggedIn()) {
          console.log('You are not allowed to view this page. You are redirected to login Page');
  
          const queryParamEntries = route.queryParamMap.keys
            .map(key => [key, route.queryParamMap.get(key)])
  
          const fullUrl = route.parent.url.join('/') + '/' + route.url.join('/');
  
          // Store the attempted URL for redirecting
          this.authService.redirectUrl = fullUrl;
  
          this.router.navigate(['/signin'], {
            queryParams: {
            },
            queryParamsHandling: "merge"
          });
          return false;
  
          //var urlTree = this.router.createUrlTree(['login']);
          //return urlTree;
        }
        return true;
      }
  
  }
  