import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-funishings-list',
  templateUrl: './funishings-list.component.html',
  styleUrls: ['./funishings-list.component.scss']
})
export class FunishingsListComponent implements OnInit {

  furnishings : any[]
  constructor() { }

  ngOnInit() {
  }

  onSort(event: any) {

  }
}