import {ApiService, GetOpts} from './api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Property, PropertyCriteria} from '../../properties/properties/model'

@Injectable()
export class PropertyService {

  constructor(private api: ApiService) {}

  searchProperties(criteria: PropertyCriteria): Observable<Property[]> {
    return this.api.getJson(`${this.api.url}/properties`, { defaultIfNull:[]});
  }

  getProperty(name: string, opts:GetOpts = {}): Observable<Property> {
    return this.api.getJson(`${this.api.url}/properties/${name}`, opts);
  }

  updateProperty(property: Property): Observable<Property> {
    return this.api.putJson(`${this.api.url}/properties/${property.id}`, property);
  }

  saveProperty(property: Property): Observable<Property> {
    return this.api.postJson(`${this.api.url}/properties`, property);
  }

  deleteProperty(name: string): Observable<void> {
    return this.api.delete(`${this.api.url}/properties/${name}`);
  }
}
