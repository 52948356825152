import { Component, OnInit } from '@angular/core';
import { TransactionService } from '../../../core/services/transaction.service';
import { Router, ActivatedRoute } from '@angular/router';
import { Transactions } from '../model';
import { RemoveConfimModalComponent } from '../../../core/components/remove-confim-modal/remove-confim-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { EditorConf, TransactionEditMode } from '../transaction-edit/transaction-edit.component';

enum TransactionsListMode {
  view, edit
}

@Component({
  selector: 'app-transactions-list',
  templateUrl: './transactions-list.component.html',
  styleUrls: ['./transactions-list.component.scss']
})
export class TransactionsListComponent implements OnInit {

  protected Modes = TransactionsListMode

  protected editorConf: EditorConf = null
  protected mode: TransactionsListMode = TransactionsListMode.view

  protected transactions: Transactions
  protected tags: string[] = []


  constructor(
    private transactionService: TransactionService,
    private modalService: NgbModal,
    private router: Router,
    private route: ActivatedRoute) { }

  ngOnInit() {

    this.route.queryParams.subscribe(params => {
      let tags = params.tags
      if (tags && tags != "") {
        this.tags = tags.split(",")
      } else {
        this.tags = []
      }
      this.loadTable()
    })
  }


  removeTag(tag: string) {
    this.tags = this.tags.filter(t => t !== tag)
    this.onTagUpdate()
  }

  selectTag(tag: string) {
    if (this.tags.filter(t => t === tag).length == 0) {
      this.tags.push(tag)
      this.onTagUpdate()
    }
  }

  onTagUpdate() {
    this.router.navigate(['.'], {
      relativeTo: this.route,
      queryParams: {
        tags: this.tags.join(),
      },
      queryParamsHandling: 'merge',
    });
  }


  // Load table of transactions data
  loadTable() {
    this.transactionService.search({ tags: Array.from(this.tags) }).subscribe(transactions => {
      this.transactions = transactions
    })
  }

  // Sort the transaction table
  // TODO: 
  onSort(event) {

  }

  onSideWindowChange(){
    this.mode = this.Modes.view
  }

  add(): void {
    this.editorConf = {mode: TransactionEditMode.create }
    this.mode = TransactionsListMode.edit
  }

  // Open a modal for the invoice edition
  edit(transactionId: string) {
    this.editorConf = {
      mode: TransactionEditMode.edit, 
      entityId: transactionId
    }
    this.mode = TransactionsListMode.edit
  }


  // Open a modal for the invoice edition
  clone(transactionId: string) {
    this.editorConf = {
      mode: TransactionEditMode.clone, 
      entityId: transactionId
    }
    this.mode = TransactionsListMode.edit
  }

  // Remove the given transaction
  remove(transactionId: string, name: string) {

    const modalRef = this.modalService.open(RemoveConfimModalComponent, {});
    modalRef.componentInstance.entity = name

    modalRef.result.then(confirmed => {

      // remove only if user confirm
      if (confirmed) {
        this.transactionService
          .deleteTransaction(transactionId)
          .subscribe(() => {
            this.loadTable()
          }, error => {
            console.log(error);
          })
      }

    }).catch((error) => {
      console.log(error);
    });
  }

  finishEdit() {
    this.mode = TransactionsListMode.view
    this.loadTable()
  }

}
