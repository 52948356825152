import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../core/services/authentication.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-signin',
  templateUrl: './signin.component.html',
  styleUrls: ['./signin.component.scss']
})
export class SigninComponent implements OnInit {

  error: string = null;
  login: string = "";
  password: string = "";


  constructor(
    private authService: AuthenticationService,
    private router: Router,
  ) { }

  ngOnInit() {
  }

  authenticate(): void {
    this.authService.authenticate(this.login, this.password).subscribe(loggedIn => {
        if (loggedIn) {
          this.router.navigate([this.authService.redirectUrl], {queryParamsHandling: "preserve"});
        } else {
          console.log('authenticate loggedIn error', this.error);
          this.error = 'Invalid login or password';
        }
      }
    )
  }
    
}
