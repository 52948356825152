import { FormGroup } from "@angular/forms";
import * as moment from 'moment';

export default class FormHelper {

    static getFormValue(form: FormGroup, path: string): any{

        let control = form.get(path)
        if( !control ) {
            console.debug("Missing form control "+ path)
            return null
        }
        return control.value
    }

    static getArrayValue(form: FormGroup, path: string, separator="," ):string[] {

        let value = FormHelper.getFormValue(form, path)

        

        if (value) {
            if (typeof value == "string"){
                return value.split(separator)
            }
            if (Array.isArray(value)){
                return value
            }
        } 
        return null
    }

    static getDateValue(form: FormGroup, path: string ):Date {

        let value = FormHelper.getFormValue(form, path)
        if (value) {

            if (value.includes("Z")){
                return moment(String(value)).toDate();
            }
            
            let date = moment(String(value),'DD/MM/YYYY').toDate();
            return date
        }
        return null
    }

    static getFloatValue(form: FormGroup, path: string): Number{

        let value = FormHelper.getFormValue(form, path)
        if (value) {
            return parseFloat(String(value))
        }
        return null
    }

    static getInValue(form: FormGroup, path: string): Number{
        
        let value = FormHelper.getFormValue(form, path)
        if (value) {
            return parseInt(String(value), 10)
        }
        return null
    }
    
    static clearForm(form: FormGroup) {
		let formValue = { ...form.value };

		for (let prop in formValue) {
			if (!formValue[prop]) {
				delete formValue[prop];
			}

			if (Array.isArray(formValue[prop])) {
				let resultArray = formValue[prop].filter(item => item);
				if (resultArray.length > 0) {
					formValue[prop] = resultArray;
				} else {
					delete formValue[prop];
				}
			}
		}
	}
}
