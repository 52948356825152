import { Component, OnInit } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-remove-confim-modal',
  templateUrl: './remove-confim-modal.component.html',
  styleUrls: ['./remove-confim-modal.component.scss']
})
export class RemoveConfimModalComponent implements OnInit {


  public entity: string
  
  constructor(
    public activeModal: NgbActiveModal,
  ) { }

  ngOnInit() {

  }


  confirm(){
    this.activeModal.close(true)
  }

  abort(){
    this.activeModal.dismiss()
  }

}
