import { Component, OnInit } from '@angular/core';
import { Tenant } from '../model';
import { TenantService } from 'src/app/core/services/tenant.service';

@Component({
  selector: 'app-tenants-list',
  templateUrl: './tenants-list.component.html',
  styleUrls: ['./tenants-list.component.scss', '../../../styles/divider.scss']
})
export class TenantsListComponent implements OnInit {

  tenants : Tenant[]
  
  tenant: Tenant

  constructor(
    private tenantService: TenantService,
  ) {}

  ngOnInit() {
    this.reload()
  }


  reload(){
    this.tenant = null
    this.tenantService.searchTenants({})
      .subscribe( tenants => {
        this.tenants = tenants

        if( this.tenants.length > 0){
          this.tenant = this.tenants[0]
        }
      })
  }

  isActive(tenant: Tenant){
    if(this.tenant){
      return this.tenant.id == tenant.id
    }
    return false;
  }

  onSelect(tenant: Tenant){
    this.tenant = tenant
  }

  onDelete(id: string){
    this.reload()
  }
}