import { Component, OnInit, Input } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { FormGroup, FormControl } from '@angular/forms';
import { ApiService } from '../../services/api.service';

@Component({
  selector: 'app-add-photo-modal',
  templateUrl: './add-photo-modal.component.html',
  styleUrls: ['./add-photo-modal.component.scss']
})
export class AddPhotoModalComponent implements OnInit {

  @Input()
  form: FormGroup

  fileToUpload: File = null;
  
  constructor(
    public activeModal: NgbActiveModal,
    private api: ApiService) { }

  ngOnInit() {
    this.initForm()
  }


  handleFileInput(files: FileList) {
    this.fileToUpload = files.item(0);
  }


  uploadFileToActivity() {
    this.api.uploadFile(`${this.api.url}/photo`,  this.fileToUpload)
      .subscribe(data => {
      // do something, if upload success
      }, error => {
        console.log(error);
      });
  }

  initForm() {

    this.form =  new FormGroup({
        'type': new FormControl(null, []),
        'sharing': new FormControl(null, []),
        'description': new FormControl(null, []),
        'file': new FormControl(null, [])
    })
  }
}
