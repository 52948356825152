import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TicketService } from 'src/app/core/services/ticket.service';
import { Ticket } from '../model';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import { TicketHelper } from 'src/app/core/helper/ticket';
import { DownloadUtils } from 'src/app/core/helper/download';
import { months } from 'moment';


@Component({
  selector: 'app-ticket-edit',
  templateUrl: './ticket-edit.component.html',
  styleUrls: ['./ticket-edit.component.scss']
})
export class TicketEditComponent implements OnInit {

  // global component status
  protected status: string = "LOADING"
  protected errorMsg: string = null

  // I voice identifier
  private invoiceId: string 

  protected ticket: Ticket

  constructor(
    private route : ActivatedRoute,
    private ticketService: TicketService,
    private invoiceService: InvoiceService,
    ) { }

  ngOnInit() {
  
    this.route.params.subscribe(params => {

      // getting invoice ID from URL
      this.invoiceId = params["invoice_id"];

      this.invoiceService
        .getTicket(this.invoiceId, true, {})
        .subscribe(ticket => {
          this.ticket = ticket
          this.status = "LOADED"
        }, err=> {
          this.status = "ERROR"
        } )
    });
  }

  getMonthAsLabel( monthNum: number, local?: string ): string{

    if( monthNum < 1 || monthNum > 12){
      return monthNum + ""
    }
    var months =  ["janvier", "fevrier", "mars", "avril", "mai", "juin", "juillet", "aout"
    ,"septembre", "octobre", "novembre", "decembre"]

    

    return months[monthNum-1].toLocaleUpperCase()
  }

  save(){
    this.ticketService.updateTicket(this.ticket).subscribe( ()=> {})
  }

  downloadPDF(){

    this.ticketService.updateTicket(this.ticket).subscribe( (t)=> {

      var ticketName = TicketHelper.getTicketName(t)

      this.invoiceService.downloadPDFTicket(this.ticket.invoiceId).subscribe(data => {
        DownloadUtils.createAndDownloadBlobFile(data,  { type: 'application/pdf' }, ticketName);
      })
    })

  }
}
