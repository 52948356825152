import { Component, OnInit } from '@angular/core';

import { Chart } from '../../../../../../node_modules/chart.js/dist/Chart.js'; 
import * as moment from 'moment'
import { TransactionService } from 'src/app/core/services/transaction.service.js';

export class Data {  
  PlayerName :string;  
  Run:string;  
}

@Component({
  selector: 'app-finances-viewer',
  templateUrl: './finances-viewer.component.html',
  styleUrls: ['./finances-viewer.component.scss']
})
export class FinancesViewerComponent implements OnInit {
 
  revenue : Array<Number>  =[];  
  expense: Array<Number>  =[]; 
  average =[]; 
  labels = [];  


  constructor(private transactionService: TransactionService) { }  

  ngOnInit() {  
    this.drawChart2()
  }  

  drawChart2(){
    
    this.transactionService.statsByMonth().subscribe(result => {  
      result.buckets.forEach(x => {  

        var strMon = (x.id.month+"").padStart(2, '0')
        var d = Date.parse( x.id.year+"-"+strMon+"-01T00:00:00")


        this.labels.push(d);  
        this.revenue.push(x.credit);  
        this.expense.push(-x.debit); 
        this.average.push(x.total); 
      });  

      this.loadChart()
    })
  }


  loadChart(){
    let canvasElt: HTMLCanvasElement = <HTMLCanvasElement> document.getElementById('canvas')

    
    var ctx: CanvasRenderingContext2D = <CanvasRenderingContext2D> canvasElt.getContext("2d",  { antialias: false, depth: false } );

    var gradientStroke = ctx.createLinearGradient(0, 0, 0, -1000);
    //gradientStroke.addColorStop(0, '#e41987');
    //gradientStroke.addColorStop(1, '#e41987');
    
    gradientStroke.addColorStop(0, '#FF4C60');
    gradientStroke.addColorStop(1, '#FF4C60');


    var gradientStrokeBlue = ctx.createLinearGradient(0, 450, 0, -800);
    gradientStrokeBlue.addColorStop(0, '#6C6CE5');
    gradientStrokeBlue.addColorStop(1, '#6C6CE5');

    
    var myChart = new Chart(ctx, {
        type: 'line',
        data: {
            labels: this.labels, 
            datasets: [{
                label: "Average",
                borderColor: '#2A2369',
                pointBorderColor:  '#2A2369',
                pointBackgroundColor: "#ffffff",
                pointBorderWidth: 2,
                
                pointHoverBackgroundColor: '#2A2369',
                pointHoverBorderColor: '#2A2369',
                pointHoverRadius: 2,
                pointHoverBorderWidth: 2,

                pointRadius: 4,

                lineTension: 0,
                fill: false,
                borderWidth: 1,
                data: this.average
            },
            {
              label: "Expense",
              //barPercentage: 0.5,
              barThickness: 8,
              //maxBarThickness: 8,
              //minBarLength: 2,

              borderColor: gradientStroke,
              pointBorderColor: gradientStroke,
              pointBackgroundColor: gradientStroke,
              pointBorderWidth: 2,
              backgroundColor: gradientStroke,
              pointRadius: 4,
              fill: true,
              borderWidth: 5,
              data: this.expense,
              type: "bar"
            },
            {
              label: "Revenue",
              //barPercentage: 0.5,
              barThickness: 8,
              //maxBarThickness: 8,
              //minBarLength: 2,

              borderColor: gradientStrokeBlue,
              pointBorderColor: gradientStrokeBlue,
              pointBackgroundColor: gradientStrokeBlue,
              pointBorderWidth: 2,
              backgroundColor: gradientStrokeBlue,
             
              pointRadius: 4,
              fill: true,
              borderWidth: 5,
              data: this.revenue,
              type: "bar"
          }]
        },
        options: {   
            responsive: true,       
            legend: {
                display: false,
                position: "bottom"
            },
            scales: {
              
                yAxes: [{
                    ticks: {
                      fontColor: "rgba(65, 63, 87,1)",
                        
                        //fontStyle: "bold",
                        beginAtZero: true,
                        maxTicksLimit: 5,
                        padding: 20
                    },
                    gridLines: {
                      //zeroLineColor: "transparent"

                    },
                    stacked: false

                }],
                xAxes: [{
                    gridLines: {
                      drawTicks: false,
                      display: false
                    },
                    ticks: {
                     
                        beginAtZero:true,
                    
                       // padding: 20,
                        fontColor: "rgba(65, 63, 87,0.5)",
                        //fontStyle: "bold"
                        callback: function(value, index, values) {
                            return  moment(value).format('MM/YYYY'); 
                        }          
                    },
                    stacked: false
                }]
            }
        }
    });
  }



}


