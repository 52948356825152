import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tenancy-documents-tab',
  templateUrl: './tenancy-documents-tab.component.html',
  styleUrls: ['./tenancy-documents-tab.component.scss']
})
export class TenancyDocumentsTabComponent implements OnInit {
  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean
  
  ngOnInit(): void {
  }
}
