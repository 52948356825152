import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-invoice-status-viewer',
  templateUrl: './invoice-status-viewer.component.html',
  styleUrls: ['./invoice-status-viewer.component.scss']
})
export class InvoiceStatusViewerComponent implements OnInit {

  @Input()
  public status :string

  constructor() { }

  ngOnInit() {
  }

}
