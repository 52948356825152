import {ApiService, GetOpts} from './api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {User, UserCriteria} from "../models/user"

@Injectable()
export class UserService {

  constructor(private api: ApiService) {}

  searchUsers(criteria: UserCriteria): Observable<User[]> {
    return this.api.getJson(`${this.api.url}/users`, {defaultIfNull:[]});
  }

  getMe(opts:GetOpts = {}): Observable<User> {
    return this.api.getJson(`${this.api.url}/users/_me`, opts);
  }

  getUser(name: string, opts:GetOpts = {}): Observable<User> {
    return this.api.getJson(`${this.api.url}/users/${name}`, opts);
  }

  updateUser(user: User): Observable<User> {
    return this.api.putJson(`${this.api.url}/users/${user.id}`, user);
  }

  saveUser(user: User): Observable<User> {
    return this.api.postJson(`${this.api.url}/users`, user);
  }

  deleteUser(name: string): Observable<void> {
    return this.api.delete(`${this.api.url}/users/${name}`);
  }
}
