import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Property } from '../../model';
import { PropertyService } from 'src/app/core/services/property.service';
import { TenancyService } from 'src/app/core/services/tenancie.service';
import { Tenancy } from 'src/app/tenancies/model';
import { ToastService } from 'src/app/core/services/toast.service';

@Component({
  selector: 'app-property-view',
  templateUrl: './property-view.component.html',
  styleUrls: ['./../../../../../styles/entity-view.scss','./property-view.component.scss']
})
export class PropertyViewComponent implements OnInit {

  _property: Property

  tenancies: Tenancy[]

  @Output()
  delete = new EventEmitter();

  constructor(
    private propertyService: PropertyService,
    private tenancyService: TenancyService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
  }


  @Input()
  set property(property: Property) {
    this._property = property
    this.load()
  }

  get property(): Property {
    return this._property
  }

  load() {
    if (this._property) {
      this.tenancyService.searchTenancies({ propertyId: this._property.id, active: true }).subscribe(tenancies =>
        this.tenancies = tenancies
      )
    }
  }

  remove(id: string) {
    // TODO confirmation
    this.propertyService.deleteProperty(id).subscribe(
      _ => {
        console.group("remove emitted")
        this.delete.emit(id)
      },
      err => {
        this.toastService.show('A error occured while removing property', { classname: 'alert alert-danger', delay: 5000 });
      })
  }
}
