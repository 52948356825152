import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import { Transactions, Transaction } from '../model';
import { FormGroup, FormControl } from '@angular/forms';
import { TransactionService } from 'src/app/core/services/transaction.service';
import { ToastService } from 'src/app/core/services/toast.service';
import FormHelper from 'src/app/core/helper/form';
import { Observable } from 'rxjs';
import { Property } from 'src/app/properties/properties/model';
import { PropertyService } from 'src/app/core/services/property.service';


export class EditorConf {
  mode: TransactionEditMode
  entityId?: string
}

export enum TransactionEditMode {
  edit, create, clone
}

enum Status {
  loading, loaded, error, notFound
}

@Component({
  selector: 'app-transaction-edit',
  templateUrl: './transaction-edit.component.html',
  styleUrls: ['./transaction-edit.component.scss']
})
export class TransactionEditComponent implements OnInit {

  protected Modes = TransactionEditMode
  protected Status = Status
  
  allProperties : Property[]

  // global component status
  status: Status = Status.loading
  
  // editor default config is create
  _config: EditorConf = {
    mode: TransactionEditMode.create
  }

  formSubmited: boolean = false
  validationError: string = null 

  @Output()
  private onChange: EventEmitter<boolean>  = new EventEmitter<boolean>()

  protected transaction: Transaction

  protected form: FormGroup

  constructor(
    private transactionService: TransactionService,
    private propertyService: PropertyService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
    this.propertyService.searchProperties({}).subscribe(properties => {
      this.allProperties = properties
    })
  }

  @Input()
  set config(config: EditorConf){
    this._config = config
    this.load()
  }

  // User ask to abord modification
  cancel(){
    this.onChange.emit(false)
    return false
  }

  load(){
    this.status = Status.loading
    this.initForm()
    this.transaction = null


    if(this._config.mode == TransactionEditMode.edit || this._config.mode == TransactionEditMode.clone){
      
      this.transactionService
        .getTransaction(this._config.entityId, { allow404: true })
        .subscribe(transaction => {

          if(this._config.mode == TransactionEditMode.clone){
            transaction.id = null
          }
          this.transaction = transaction
          this.objectToForm(this.transaction)
          this.status = Status.loaded

        }, err => {
          this.validationError = "Fail to load transaction"
          this.status = Status.error
          console.log(err)
        })
    }else{
      this.status = Status.loaded
    }
  }

  // initialize form field
  initForm(){

    this.form =  new FormGroup({
      'propertyId': new FormControl(null, []),
      'reference': new FormControl(null, []),
      'documentId': new FormControl(null, []),
      'date': new FormControl(null, []),
      'tags': new FormControl(null, []),
      'amount': new FormControl(null, []),
      'comment': new FormControl(null, []),
      'label': new FormControl(null, []),
    })
  }

  objectToForm(transaction: Transaction ): void  {
		var formPatch = JSON.parse(JSON.stringify(transaction));
		this.form.patchValue(formPatch)
	}

	formToObject(transaction: Transaction): Transaction {

    if(!transaction) {
      transaction = <Transaction>{}
    }

    transaction = Object.assign(transaction, this.form.value);
    transaction.amount =  FormHelper.getFloatValue( this.form, "amount")
    transaction.tags = FormHelper.getArrayValue(this.form, "tags")
    transaction.date = FormHelper.getDateValue(this.form, "date")
    transaction.id = this._config.entityId
		return transaction
	}





  onSubmit(){

    this.formSubmited = true

		if (this.form.valid) {

			let transaction = this.formToObject(this.transaction)

			let save$ : Observable<Transaction>
			if (this._config.mode == this.Modes.edit) {
				save$ = this.transactionService.updateTransaction(transaction)
			} else {
				save$ = this.transactionService.saveTransaction(transaction)
			}

			save$.subscribe(
				_ => { this.onChange.emit(true) },
				error => { this.toastService.show('A error occured while saving transaction', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
      this.validationError = "Invalid form entries"
			console.log(this.form)
		
		}
  }
}
