import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { TenantService } from 'src/app/core/services/tenant.service';
import { Tenant } from 'src/app/tenants/model';

@Component({
  selector: 'app-add-tenant-modal',
  templateUrl: './add-tenant-modal.component.html',
  styleUrls: ['./add-tenant-modal.component.scss']
})
export class AddTenantModalComponent implements OnInit {

  form: FormGroup

  fileToUpload: File = null;

  tenants: Tenant[] = []
  tenant: Tenant = null
  
  constructor(
    public modal: NgbActiveModal,
    private tenantService: TenantService) { }

  ngOnInit() {
    this.tenantService.searchTenants({}).subscribe( tenants => {
      this.tenants = tenants

      if(this.tenants.length > 0) {
        this.tenant = this.tenants[0]
      }
    });
    this.initForm()
  }

  initForm() {

    this.form =  new FormGroup({
        'type': new FormControl(null, []),  
    })
  }


  onChange(tenantId: string) {
   let tenantsFilters = this.tenants.filter(t => { return t.id === tenantId})

   if( tenantsFilters.length== 0){
      this.tenant = null
   }else{
    this.tenant =  tenantsFilters[0]
   }
  }

  validate(){
    this.modal.close(this.tenant)
  }

}
