import { Component, OnInit, Input } from '@angular/core';
import { Tenant } from 'src/app/tenants/model';

@Component({
  selector: 'app-tenants-avatars-viewer',
  templateUrl: './tenants-avatars-viewer.component.html',
  styleUrls: ['./tenants-avatars-viewer.component.scss']
})
export class TenantsAvatarsViewerComponent implements OnInit {

  constructor() { }

  @Input()
  tenants: Tenant[];

  ngOnInit() {
  }

  getTenantInitials(tenant: Tenant) : string {

    if(!tenant || !tenant.personalDetail || !tenant.personalDetail.firstName || !tenant.personalDetail.lastName ){
      return "??"
    }
    return (tenant.personalDetail.firstName[0] + tenant.personalDetail.lastName[0]).toUpperCase()
  }

}
