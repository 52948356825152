import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-arrangement-view',
  templateUrl: './arrangement-view.component.html',
  styleUrls: ['./arrangement-view.component.scss']
})
export class ArrangementViewComponent implements OnInit {

  constructor() { }

  @Input()
  label: string = "..."

  @Input()
  quantity: string = "0"

  ngOnInit() {
  }

}
