import { FormGroup, FormControl } from "@angular/forms"
import { User } from 'src/app/core/models/user';
import FormHelper from 'src/app/core/helper/form';

export class FormMapper{

      // initialize form field
  initForm(): FormGroup{

    return new FormGroup({ 
      'firstName': new FormControl('', []) ,
      'lastName': new FormControl('', []),
      'avatar': new FormControl('', []) ,
      //'birthDate': new FormControl('', []) ,
      'phone': new FormControl('', []) ,
      'email': new FormControl('', []) ,

      'address': new FormGroup({
        'line1': new FormControl('', []) ,
        'line2': new FormControl('', []),
        'zipCode': new FormControl('', []) ,
        'city': new FormControl('', []) ,
        'country': new FormControl('', []) ,
      }),
    })
  }

  loadForm(form: FormGroup, user: User){ 
    if(user) {
        var formPatch = JSON.parse(JSON.stringify(user));
        form.patchValue(formPatch)
    }
  }

  loadModel( userId:string, user: User, form: FormGroup,){ 
    var formPatch = JSON.parse(JSON.stringify(user));
    form.patchValue(formPatch)

    if(!user) {
      user = <User>{}
    }
    let patchedUser: User = Object.assign(user, form.value);
    patchedUser.birthDate = FormHelper.getDateValue(form, "birthDate")  
    patchedUser.id = userId
  
    return patchedUser
  }

}