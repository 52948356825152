import { Component, OnInit, Input } from '@angular/core';
import { Tenancy } from 'src/app/tenancies/model';

@Component({
  selector: 'app-property-tenancy-view',
  templateUrl: './tenancy-view.component.html',
  styleUrls: ['./tenancy-view.component.scss']
})
export class PropertyTenancyViewComponent implements OnInit {

  constructor() { }

  @Input()
  tenancy: Tenancy

  ngOnInit() {
  }

}
