import { Component, OnInit, Input } from '@angular/core';
import { BankDetails } from 'src/app/tenants/model';

@Component({
  selector: 'app-bank-detail-viewer',
  templateUrl: './bank-detail-viewer.component.html',
  styleUrls: ['./bank-detail-viewer.component.scss']
})
export class BankDetailViewerComponent implements OnInit {

  @Input()
  bankDetails : BankDetails
  
  constructor() { }

  ngOnInit() {
  }


}
