
export class PropertyCriteria{

}

export class Property {
    id?: string;
    reference?: string
    type?: string;
    status?: string;
    address?: Address;
    description?: Description;
    additionalInfo?: AdditionalInfo;
    landRegistry?: LandRegistry;
    tenancyInformation?: TenancyInformation;
    financialInfo?: FinancialInfo;
    taxOffice?: TaxOffice;
    contracts?: Contract[];
    photos?: Photo[];
    documents?: Document[];
}
  

export interface FinancialInfo {
    councilTax?: Number;
    propertyTax?: Number;
    dateAcquired?: Date;
    propertyPurchasePrice?: Number;
    acquisitionFees?: Number;
}
    
export interface TaxOffice {
    officeName?: string;
    address1?: string;
    address2?: string;
    postCode?: string;
    city?: string;
    notes?: string;
}
    
export interface Contract {
    type?: string;
    file?: string;
    description?: string;
    dateOfIssue?: Date;
    expiryDate?: Date;
}

export interface Address {
    address1?: string;
    address2?: string;
    building?: string;
    entrance?: string;
    floor?: string;
    number?: string;
    city?: string;
    postCode?: string;
    country?: string;
}
    
export interface AdditionalInfo {
    typeOfHome?: string;
    buildingClassification?: string;
    furnished?: boolean;
    smokersAllowed?: boolean;
    petsAllowed?: boolean;
    furnitures?: Furnitures;
    parking?: string;
    otherAnnexes?: string;
    basement?: string;
    lot?: string;
    proportion?: string;
}
    
export interface Furnitures {
    internetAccess?: boolean;
    playground?: boolean;
    elevador?: boolean;
    barbecue?: boolean;
    basement?: boolean;
    sportsClub?: boolean;
    parksAndGardens?: boolean;
    garage?: boolean;
    doorman?: boolean;
    garden?: boolean;
    parking?: boolean;
    tennis?: boolean;
    garbageDisposer?: boolean;
    airConditioning?: boolean;
    satelliteTV?: boolean;
    balcony?: boolean;
    cable_Fiber?: boolean;
    communalHeating?: boolean;
    collectiveHotWater?: boolean;
    gym?: boolean;
    bikeStorage?: boolean;
    intercom?: boolean;
    laundry?: boolean;
    pool?: boolean;
    remoteSurveillance?: boolean;
    patio?: boolean;
}
    
export interface LandRegistry {
    plan?: string;
    parcel?: string;
    category?: string;
    tenancyValue?: string;
}
    
  
export interface Description {
    size?: Number;
    numberOfRooms?: string;
    numberOfBedrooms?: string;
    bathrooms?: string;
    yearOfConstruction?: string;
    description?: string;
    notes?: string;
}
    
export interface TenancyInformation {
    type?: string;
    rent?: Number;
    charges?: Number;
}
    
export interface Photo {
    name?: string
    url?: string;
}

