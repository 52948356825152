import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { Tenant } from 'src/app/tenants/model';
import { Property } from 'src/app/properties/properties/model';
import { PropertyService } from 'src/app/core/services/property.service';
import { AddTenantModalComponent } from '../../modals/add-tenant-modal/add-tenant-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-tenancy-general-tab',
  templateUrl: './tenancy-general-tab.component.html',
  styleUrls: ['./tenancy-general-tab.component.scss'],
})
export class TenancyGeneralTabComponent implements OnInit {

  constructor(
    private propertyService: PropertyService,
    private modalService: NgbModal,
  ) { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean

  // all available choices
  allProperties : Property[]
  
  ngOnInit(): void {
    this.propertyService.searchProperties({}).subscribe(properties => {
      this.allProperties = properties
    })
  }

  tenantToFormControl(tenant: Tenant): FormGroup {
    return new FormGroup(
      {
        id: new FormControl(tenant.id, [Validators.required]),
        type: new FormControl(tenant.type, []),
        firstName: new FormControl(tenant.personalDetail.firstName, []),
        lastName: new FormControl(tenant.personalDetail.lastName, []),  
      },
    )
  }

  addTenant(): void {

      const modalRef = this.modalService.open(AddTenantModalComponent, { size: 'lg' });
      
      modalRef.result.then(tenant => {
        let tenantsForm = <FormArray> this.form.get("tenants")
        tenantsForm.push(this.tenantToFormControl(tenant))

      }).catch((error) => {
        console.log(error);
      });
  }
}




