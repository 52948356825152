import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-property-documents-tab',
  templateUrl: './property-documents-tab.component.html',
  styleUrls: ['./property-documents-tab.component.scss']
})
export class PropertyDocumentsTabComponent implements OnInit {


  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean
  
  ngOnInit(): void {
  }

}
