import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { AccountService } from 'src/app/core/services/account.service';
import { AccountCreation } from 'src/app/core/models/authentication';
import { User } from 'src/app/core/models/user';

@Component({
  selector: 'app-signup',
  templateUrl: './signup.component.html',
  styleUrls: ['./signup.component.scss']
})
export class SignupComponent implements OnInit {

  public formSubmited: boolean = false

  constructor(
    private toastService: ToastService,
    private accountService: AccountService
  ) { }

  form: FormGroup

  ngOnInit() {
    this.initForm()
  }

  initForm() {
    this.form = new FormGroup({
      'firstName': new FormControl(null, [Validators.required]),
      'lastName': new FormControl(null, [Validators.required]),
      'email': new FormControl(null, [Validators.required]),
      'password': new FormControl(null, [Validators.required]),
      'password2': new FormControl(null, [Validators.required])

    })
  }

  formToObject(): AccountCreation {
    let accountRequest = new AccountCreation()

    accountRequest.login = this.form.get('email').value
    accountRequest.password = this.form.get('password').value
    accountRequest.user = new User()
    accountRequest.user.firstName = this.form.get('firstName').value
    accountRequest.user.lastName = this.form.get('lastName').value
    accountRequest.user.email = this.form.get('email').value

    return accountRequest
  }

  onSubmit(): void {
    this.formSubmited = true

    if (this.form.valid) {

      let accountRequest = this.formToObject()

      this.accountService.createAccount(accountRequest).subscribe(
        _ => { this.toastService.show('The account has been created', { classname: 'alert alert-primary', delay: 5000 }); },
        error => { this.toastService.show('A error occured while creating account', { classname: 'alert alert-danger', delay: 5000 }); }
      )
    } else {
      this.toastService.show('Invalid form ', { classname: 'alert alert-danger', delay: 5000 });
    }
  }

}
