import { Component, OnInit, Input, assertPlatform } from '@angular/core';
import { HttpClient, HttpEventType, HttpHeaders } from '@angular/common/http';
import { UploadResponse } from 'src/app/core/models/documents';
import { Property, Photo } from '../../../model';
import { PropertyService } from 'src/app/core/services/property.service';
import { map, flatMap } from 'rxjs/operators';

@Component({
	selector: 'app-property-photos-tab',
	templateUrl: './property-photos-tab.component.html',
	styleUrls: ['./property-photos-tab.component.scss']
})
export class PropertyPhotosTabComponent implements OnInit {

	fileData: File = null;
	previewUrl: any = null;
	fileUploadProgress: string = null;
	uploadedFilePath: string = null;

	photos: Photo[] = []

	constructor(
		private http: HttpClient,
		private propertyService: PropertyService,
		) { }

	@Input()
	private propertyId: string 
  

  
	ngOnInit(): void {
		this.loadPhoto()
	}
  

	loadPhoto(){
		this.propertyService.getProperty(this.propertyId).subscribe(
			property => {
			  if(!property.photos){
				property.photos = []
			  }
			  this.photos = property.photos
			}
		  )
	}
	

	fileProgress(fileInput: any) {
		this.fileData = <File>fileInput.target.files[0];
		this.preview();

		this.onSubmit()
	}

	preview() {
		// Show preview 
		var mimeType = this.fileData.type;
		if (mimeType.match(/image\/*/) == null) {
			return;
		}

		var reader = new FileReader();
		reader.readAsDataURL(this.fileData);
		reader.onload = (_event) => {
			this.previewUrl = reader.result;
		}
	}

	onSubmit() {
		const formData = new FormData();
		formData.append('file', this.fileData);

		this.fileUploadProgress = '0%';

		this.http.post('http://localhost:8080/documents', formData, {
			reportProgress: true,
			observe: 'events',
			responseType: "json"

		})
		.subscribe(events => {
				if (events.type === HttpEventType.UploadProgress) {
					this.fileUploadProgress = Math.round(events.loaded / events.total * 100) + '%';
					console.log(this.fileUploadProgress);
				} else if (events.type === HttpEventType.Response) {
					this.fileUploadProgress = '';
					let uploadResponse: UploadResponse = events.body
					
					// Add photo to save
					this.photos.push(  {
						name: uploadResponse.name,
						url: uploadResponse.location
					} )

					this.propertyService
						.getProperty(this.propertyId)
						.pipe(
							map( p => { 
								if(!p.photos){
									p.photos = []
								}
								p.photos.push({ name: uploadResponse.name, url: uploadResponse.location })
								return p
							}),
							flatMap( p =>  this.propertyService.updateProperty(p))
						)
						.subscribe( _ => { this.loadPhoto()})
				}
			},	 
			error => { 
				console.log(error) 
			},
			() => {
				this.fileUploadProgress = ""
				this.previewUrl = ""
			}
		);
	}
}
