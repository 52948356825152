import { Component, OnInit, Input } from '@angular/core';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddContratModalComponent } from '../../modals/add-contrat-modal/add-contrat-modal.component';
import { Property } from '../../../model';
import { PropertyService } from 'src/app/core/services/property.service';
import { map, flatMap } from 'rxjs/operators';

@Component({
  selector: 'app-property-contracts-tab',
  templateUrl: './property-contracts-tab.component.html',
  styleUrls: ['./property-contracts-tab.component.scss']
})
export class PropertyContractsTabComponent implements OnInit {

  constructor(
    private modalService: NgbModal,
    private propertyService: PropertyService
  ) { }

  @Input()
  private propertyId: string 

  public property: Property


  ngOnInit(): void {

    this.propertyService.getProperty(this.propertyId).subscribe(
      property => {
        this.property = property
      }
    )
  }


  newContract(){
    const modalRef = this.modalService.open(AddContratModalComponent, { size: 'lg' });
      
    modalRef.result.then(contract => {

      this.propertyService
        .getProperty(this.propertyId)
        .pipe(
          map(property => {
            if (!property.contracts ){
              property.contracts = []
            }
            property.contracts.push(contract)
            return property
          })
        ).pipe(
          flatMap( property=> {
            return this.propertyService.updateProperty(property)
          }) 
        )
        .subscribe( property => {
          this.property = property
        })

    }).catch((error) => {
      console.log(error);
    });
  }

}
