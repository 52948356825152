import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tenant-additional-tab',
  templateUrl: './tenant-additional-tab.component.html',
  styleUrls: ['./tenant-additional-tab.component.scss']
})
export class TenantAdditionalTabComponent implements OnInit {

  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean
  
  ngOnInit(): void {
  }


}
