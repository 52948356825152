import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tenant-documents-tab',
  templateUrl: './tenant-documents-tab.component.html',
  styleUrls: ['./tenant-documents-tab.component.scss']
})
export class TenantDocumentsTabComponent implements OnInit {

  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean
  
  ngOnInit(): void {
  }

}
