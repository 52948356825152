import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators, FormArray } from '@angular/forms';
import { TenancyService } from 'src/app/core/services/tenancie.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenancy } from '../model';
import FormHelper from 'src/app/core/helper/form';
import { ToastService } from 'src/app/core/services/toast.service';
import { Observable } from 'rxjs';
import * as moment from 'moment';
import { Tenant } from 'src/app/tenants/model';

enum MODE {
	edit,
	create
}

@Component({
	selector: 'app-tenancy-edit',
	templateUrl: './tenancy-edit.component.html',
	styleUrls: ['./tenancy-edit.component.scss'],

})
export class TenancyEditComponent implements OnInit {

	constructor(
		private tenancyService: TenancyService,
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
	) { }

	/** Formulaire current mode */
	mode: MODE = MODE.edit;
	tab: string = "general";

	tenancyId: string
	tenancyForm: FormGroup

	formSubmited: boolean = false


	ngOnInit(): void {

		this.initForm()

		if (this.router.url === "/tenancies/new") {
			this.mode = MODE.create;

		} else { // If environment update
			this.mode = MODE.edit;

			// Initialise screen
			this.route.params.subscribe(params => {
				this.tenancyId = params["tenancy_id"];

				this.tenancyService
					.getTenancy(this.tenancyId)
					.subscribe(tenancy => {
						this.objectToForm(tenancy)
					})
			});
		}
	}

	tenantToFormControl(tenant: Tenant): FormGroup {

		let group = new FormGroup({
			id: new FormControl(tenant.id, [Validators.required]),
			type: new FormControl(tenant.type, []), 
		  },
		)

		if( tenant.personalDetail) {
			group.controls.firstName =  new FormControl(tenant.personalDetail.firstName, [])
			group.controls.lastName = new FormControl(tenant.personalDetail.lastName, []) 
		}

		return group
	  }

	objectToForm(tenancy: Tenancy): void {
		var formPatch = JSON.parse(JSON.stringify(tenancy));
		


		if (tenancy.tenants){

			let tenantsForm = <FormArray> this.tenancyForm.get("tenants")

			tenancy.tenants.forEach(t => {
				console.log("Adding tenant ")
				tenantsForm.push(this.tenantToFormControl(t))
			})
		}
		this.tenancyForm.patchValue(formPatch)

	}

	formToObject(): Tenancy {
		let tenancy: Tenancy = Object.assign({}, this.tenancyForm.value);

		tenancy.rent.rentExcludingCharges =  FormHelper.getFloatValue( this.tenancyForm, "rent.rentExcludingCharges")
		tenancy.rent.charges.amount =  FormHelper.getFloatValue( this.tenancyForm, "rent.charges.amount")
		tenancy.rent.rent =  FormHelper.getFloatValue( this.tenancyForm, "rent.rent")

		tenancy.tenancyDetails.paymentDate = FormHelper.getInValue(this.tenancyForm, "tenancyDetails.paymentDate")
		tenancy.tenancyDetails.receiptDate = FormHelper.getInValue(this.tenancyForm, "tenancyDetails.receiptDate")

		tenancy.LHAAutority = FormHelper.getFloatValue( this.tenancyForm,"LHAAutority")
		tenancy.advancedRentPayments = FormHelper.getInValue( this.tenancyForm,"advancedRentPayments")

		tenancy.rentLimitation.rentAmountPerMSquare = FormHelper.getFloatValue( this.tenancyForm,"rentLimitation.rentAmountPerMSquare")
		tenancy.rentLimitation.rentIncreasePerMSquare = FormHelper.getFloatValue( this.tenancyForm,"rentLimitation.rentIncreaseParMSquare")
		tenancy.rentLimitation.additionalRent = FormHelper.getFloatValue( this.tenancyForm,"rentLimitation.additionalRent")
		tenancy.rentLimitation.lastRent = FormHelper.getFloatValue( this.tenancyForm,"rentLimitation.lastRent")
		tenancy.rentLimitation.paymentDate = FormHelper.getDateValue( this.tenancyForm,"rentLimitation.paymentDate")
		tenancy.rentLimitation.lastUpdate = FormHelper.getDateValue( this.tenancyForm,"rentLimitation.lastUpdate")

		tenancy.repairDoneByLandlord.amount = FormHelper.getFloatValue( this.tenancyForm,"repairDoneByLandlord.amount")
		tenancy.repairDoneByTenant.amount = FormHelper.getFloatValue( this.tenancyForm,"repairDoneByTenant.amount")

		tenancy.firstReceipts.charges = FormHelper.getFloatValue( this.tenancyForm,"firstReceipts.charges")
		tenancy.firstReceipts.rent= FormHelper.getFloatValue( this.tenancyForm,"firstReceipts.rent") 
	
		tenancy.otherCharges.amount = FormHelper.getFloatValue( this.tenancyForm,"otherCharges.amount")
		tenancy.securityDeposit = FormHelper.getFloatValue( this.tenancyForm,"securityDeposit")

		tenancy.lateFees = FormHelper.getFloatValue(this.tenancyForm, "lateFees")

		tenancy.id = this.tenancyId
	
		return tenancy
	}


	onSubmit(): void {

		this.formSubmited = true

		if (this.tenancyForm.valid) {

			let tenancy = this.formToObject()

			let save$ : Observable<Tenancy>
			if (tenancy.id) {
				save$ = this.tenancyService.updateTenancy(tenancy)
			} else {
				save$ = this.tenancyService.saveTenancy(tenancy)
			}

			save$.subscribe(
				_ => { this.toastService.show('The property has been saved', { classname: 'alert alert-primary', delay: 5000 }); },
				error => { this.toastService.show('A error occured while saving property', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
			console.log(this.tenancyForm)
			console.log("Invalid form");
		}
	}


	selectTab(tab: string): void {
		this.tab = tab
	}


	
	initForm() {
		this.tenancyForm = new FormGroup({
			'reference': new FormControl(null, []),
			'property': new FormGroup({
				'id': new FormControl(null, [Validators.required]),
			}),
			'tenancyDetails': new FormGroup({
				'type': new FormControl(null, [Validators.required]),
				'usage': new FormControl(null, []),
				'tenancyStart': new FormControl(null, [Validators.required]),
				'tenancyEnd': new FormControl(null, []),
				'renewal': new FormControl(null, []),
				'payment': new FormControl(null, [Validators.required]),
				'paymentDate': new FormControl(null, [Validators.min(1), Validators.max(31)]),
				'receiptDate': new FormControl(null, []),
				'rentCreation': new FormControl(null, []),
				'paymentMethod': new FormControl(null, [Validators.required])
			}),
			'rent': new FormGroup({
				'rent': new FormControl(null, []),
				'rentExcludingCharges': new FormControl(null, []),
				'charges': new FormGroup({
					'amount': new FormControl(null, []),
					'type': new FormControl(null, [])
				}),
				'vatParameter': new FormControl(null, [])
			}),
			'otherCharges': new FormGroup({
				'description': new FormControl(null, []),
				'amount': new FormControl(null, [])
			}),
			'securityDeposit': new FormControl(null, []),
			'LHAAutority': new FormControl(null, []),
			'lateFees': new FormControl(null, []),
			'advancedRentPayments': new FormControl(null, []),
			'rentIncrease': new FormGroup({
				'referenceIndex': new FormGroup({
				}),
				'automaticUpdate': new FormControl(null, [])
			}),
			'rentLimitation': new FormGroup({
				'increaseRegulated': new FormControl(null, []),
				'cappedIRL': new FormControl(null, []),
				'rentAmountPerMSquare': new FormControl(null, []),
				'rentIncreasePerMSquare': new FormControl(null, []),
				'additionalRent': new FormControl(null, []),
				'description': new FormControl(null, []),
				'asPreviousTenanceEndedOver18m': new FormControl(null, []),
				'lastRent': new FormControl(null, []),
				'paymentDate': new FormControl(null, []),
				'lastUpdate': new FormControl(null, []),
				'isRentSubjectUpdating': new FormControl(null, [])
			}),
			'tenants': new FormArray([
				//new FormGroup({id: new FormControl(null, Validators.required)})
			]),
			'repairDoneByLandlord': new FormGroup({
				'amount': new FormControl(null, []),
				'description': new FormControl(null, [])
			}),
			'repairDoneByTenant': new FormGroup({
				'amount': new FormControl(null, []),
				'description': new FormControl(null, [])
			}),
			'specialTerms': new FormGroup({
				'specialTermAndConditions': new FormControl(null, []),
				'specialTermClause': new FormControl(null, [])
			}),
			'commentOnTenancy': new FormControl(null, []),
			'firstReceipts': new FormGroup({
				'prorated': new FormControl(null, []),
				'endOfTheMonth': new FormControl(null, []),
				'rent': new FormControl(null, []),
				'charges': new FormControl(null, [])
			}),
			'invoicingAddress': new FormGroup({
				'dontUseTenancyAddress': new FormControl(null, []),
				'address': new FormControl(null, [])
			}),
			'receipt': new FormGroup({
				'title': new FormControl(null, []),
				'numbering': new FormControl(null, []),
				'rentNoticeOnSecondPage': new FormControl(null, []),
				'receiptText': new FormControl(null, []),
				'dueDateText': new FormControl(null, [])
			}),
			'otherSettings': new FormGroup({
				'tenantBalanceDeferral': new FormControl(null, [])
			}),
			'notifications': new FormGroup({
				'notifyReceiptReady': new FormControl(null, []),
				'notifyTenantReceiptReady': new FormControl(null, []),
				'notifyBeforeTenancyEnd': new FormControl(null, []),
				'notifyTenantBeforeEnd': new FormControl(null, [])
			}),
			'rentHistory': new FormControl(null, []),
			'activateTenancy': new FormControl(null, []),
			'insurance': new FormGroup({
				'type': new FormControl(null, []),
				'document': new FormControl(null, []),
				'description': new FormControl(null, []),
				'dateIssue': new FormControl(null, []),
				'expiryDate': new FormControl(null, [])
			}),/*
			'documents': new FormGroup({
				'type': new FormControl(null, []),
				'sharing': new FormControl(null, []),
				'description': new FormControl(null, []),
				'file': new FormControl(null, [])
			})*/
		})

	}
}