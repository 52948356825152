import {ApiService} from './api.service';
import {Observable} from 'rxjs';
import {Injectable} from '@angular/core';
import {Tenant} from '../../tenants/model'
import { AccountCreation } from '../models/authentication';

@Injectable()
export class AccountService {

  constructor(private api: ApiService) {}

  createAccount(accountRequest: AccountCreation): Observable<Account> {
    return this.api.postJson(`${this.api.url}/accounts`, accountRequest);
  }

}
