import { HttpHeaders } from '@angular/common/http';

export const NO_CACHE_HEADERS = {
    "Cache-Control": "no-cache",
    "Expires": "Sat, 01 Jan 2000 00:00:00 GMT"
    //"Pragma": "no-cache"
  };
  
  export const JSON_HEADERS = {
    "Content-Type": "application/json; charset=utf-8",
    "Accept": "application/json; charset=utf-8"
  };
  
  export const TEXT_HEADERS = {
    "Accept": "plain/text; charset=utf-8"
  };
  
  export function headerMerge(...headers: { [name: string]: string }[]) {
    return Object.assign({}, ...headers);
  }
  
  export function reqOpts(...headers: { [name: string]: string }[]) {
    return new HttpHeaders(headerMerge(...headers));
  }
  