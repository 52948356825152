import { Injectable } from '@angular/core';
import { NgbDateAdapter, NgbDateStruct, NgbDateParserFormatter } from '@ng-bootstrap/ng-bootstrap';
import * as moment from 'moment'

/**
 * This Service handles how the date is represented in scripts i.e. ngModel.
 */
@Injectable()
export class CustomAdapter extends NgbDateAdapter<string> {

  readonly DELIMITER = '/';

  fromModel(value: string): NgbDateStruct {

    let result: NgbDateStruct = null;
    if (value && value !== "") {
      let date = moment(String(value),'YYYY-MM-DDTHH:mm:SSZ').toDate();
      result = {
        day : date.getDate(),
        month : date.getMonth()+1,
        year : date.getFullYear(),
      };
    }
    return result;
  }

  toModel(date: NgbDateStruct): string {


    let result: string = null;
    if (date) {

      let strDay = (""+(date.day) ).padStart(2, "0")
      let strMonth = (""+date.month).padStart(2, "0")

      var day = moment(date.year +"-" + strMonth +"-" +strDay);
      result = day.toISOString()
    }
    return result;
  }
}

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable()
export class CustomDateParserFormatter extends NgbDateParserFormatter {

  readonly DT_FORMAT = 'DD/MM/YYYY';

  parse(value: string): NgbDateStruct {

      let result: NgbDateStruct
      if (value) {
          value = value.trim();
          let date = moment(value, this.DT_FORMAT).toDate()
          result = {
            day : date.getDate(),
            month : date.getMonth()+1,
            year : date.getFullYear(),
          };
      }
      return result;
  }

  format(date: NgbDateStruct): string {

      if (!date) return '';
      let mdt = moment([date.year, date.month-1, date.day]);
      if (!mdt.isValid()) return '';
      
      let result =  mdt.format(this.DT_FORMAT);
      return result
  }
}