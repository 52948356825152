import { Component, OnInit, Input } from '@angular/core';
import { FormGroup } from '@angular/forms';

@Component({
  selector: 'app-tenant-guarantors-tab',
  templateUrl: './tenant-guarantors-tab.component.html',
  styleUrls: ['./tenant-guarantors-tab.component.scss']
})
export class TenantGuarantorsTabComponent implements OnInit {

  constructor() { }

  @Input()
  form: FormGroup

  @Input()
  formSubmited: boolean
  
  ngOnInit(): void {
  }

}
