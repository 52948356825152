import { Component, OnInit, Input } from '@angular/core';
import { Tenancy } from '../../model';

@Component({
  selector: 'app-tenancy-line',
  templateUrl: './tenancy-line.component.html',
  styleUrls: ['./tenancy-line.component.scss']
})
export class TenancyLineComponent implements OnInit {

  @Input()
  tenancy: Tenancy

  @Input()
  active: Boolean

  constructor() { }

  ngOnInit() {
  }

  isActive(): Boolean{
    return this.active;
  }
}
