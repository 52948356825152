import { Component, OnInit, Input } from '@angular/core';
import { User } from '../../models/user';
import { AuthenticationService } from '../../services/authentication.service';

@Component({
  selector: 'app-topbar-profile',
  templateUrl: './topbar-profile.component.html',
  styleUrls: ['./topbar-profile.component.scss']
})
export class TopbarProfileComponent implements OnInit {

  @Input()
  user: User

  constructor(
      private authService: AuthenticationService) { 
  }

  ngOnInit() {
    this.user = this.authService.getUser()
  }

  logout(){
    this.authService.logout()
  }

  getInitials(): string{


    if(!this.user || !this.user.firstName || this.user.lastName == "" ){
      return "??"
    }
    return (this.user.firstName[0] + this.user.lastName[0]).toUpperCase()
  }

}
