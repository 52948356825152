import {throwError as observableThrowError, Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient, HttpResponse} from '@angular/common/http';
import {catchError, finalize, map} from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

export const JSON_HEADERS = {
    "Content-Type": "application/json; charset=utf-8",
    "Accept": "application/json; charset=utf-8"
  };

export const UPLOAD_HEADERS= {

};
export const DOWNLOAD_HEADERS={
  "Accept": "*/*"
}


interface ErrorHandlerConfig{
  allow404?: boolean;
  defaultValue?: any;
}

export class GetOpts {
  public allow404?: boolean = false
  public defaultIfNull?: any = null
}

@Injectable()
export class ApiService {

  public url: string = "https://rentosaur.nosaures.com/api"

  isRequesting = false;

  callCount = 0;

  constructor(private http: HttpClient,
    private authService: AuthenticationService,
    ) {
  }

  private onRequestStarted() {
    this.callCount++;
    this.isRequesting = this.callCount > 0;
  }

  private onRequestStopped() {
    this.callCount--;
    this.isRequesting = this.callCount > 0;
  }

  getJson(uri: string, opts: GetOpts ): Observable<any> {

    this.onRequestStarted();

    var errHandlerCfg = <ErrorHandlerConfig>{}

    if(!opts){
      errHandlerCfg.allow404 = opts.allow404
      errHandlerCfg.defaultValue = opts.defaultIfNull
    } 

    return this.http.get(uri, { headers: this.authService.headers(JSON_HEADERS), observe: 'response'})
      .pipe(
        map(data => data.body),
        catchError(this.getHandleError(errHandlerCfg)),
        finalize(() => this.onRequestStopped())
      );
  }

  postJson(uri: string, data: any = ''): Observable<any> {
    this.onRequestStarted();
    return this.http.post(uri, data, {headers: this.authService.headers(JSON_HEADERS)})
      .pipe(
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped())
      );
  }

  putJson(uri: string, data: any = ''): Observable<any> {
    this.onRequestStarted();
    return this.http.put(uri, data, {headers: this.authService.headers(JSON_HEADERS), observe: 'response'})
      .pipe(
        map(resp => resp.body),
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped())
      );
  }



  delete(uri: string): Observable<any> {
    this.onRequestStarted();
    return this.http.delete(uri, {headers: this.authService.headers(JSON_HEADERS)})
      .pipe(
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped())
      );
  }


  uploadFile(uri: string, fileToUpload: File): Observable<any> {

    const formData: FormData = new FormData();
    formData.append('fileKey', fileToUpload, fileToUpload.name);
    return this.http.post(uri, formData, { headers: this.authService.headers(UPLOAD_HEADERS) })      
      .pipe(
        catchError(this.getHandleError()),
        finalize(() => this.onRequestStopped())
      );
  }

  downloadFile(uri: string): Observable<any> {

    return this.http.get(uri, { responseType: 'blob',  headers: this.authService.headers(DOWNLOAD_HEADERS)})
    .pipe(  
      catchError(this.getHandleError())
    );
  }
  


    getHandleError(config: ErrorHandlerConfig = {}) {

        const that = this;
        return function(e: HttpResponse<any>): Observable<any> {
        
        let custom_msg = getHTTPErrorAsMessage(e)

        if (e.status === 401) {
            that.authService.resetToken()
            return observableThrowError('Requires login');
            
        } else if (e.status === 403) {
            const msg = custom_msg || 'You are not allowed to perform this action!';
            return observableThrowError(msg);

        } else if (e.status === 400) {
            const msg = custom_msg || `Invalid action!<br>${e.toString()}`;
            return observableThrowError(msg);

        } else if (e.status === 404) {
            if (config.allow404) {
            return of(config.defaultValue);
            }
            const msg = custom_msg || `Resource not found!<br>${e.toString()}`;
            return observableThrowError(msg);

        } else if (e.status === 412) {
            const msg = `Resource has been modified since last access. Please reload your page and retry.`;
            return observableThrowError(msg);

        } else if (e.status === 0) {
            const msg = custom_msg || 'Error';
            return observableThrowError(msg);

        } else {
            const msg = custom_msg || `API error (HTTP ${e.status})!<br>${e.toString()}`;
            return observableThrowError(msg);
        }
        };
    }

}



export function getHTTPErrorAsMessage(e : any): string {

  let msg = ""
  try {
      if (e['error'] && e['error']['error_description']) {
        msg = e['error']['error_description'];

        if (e['error']['error_details']) {
          const details = e['error']['error_details'];
          for (const detail of details) {
            msg += `<br>${detail.field}: ${detail.description}`;
          }
        }
      } else if (e['message']) {
        msg = e['message'];
      }
  } catch (error) {
      // silent error
  }
  return msg

}