import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TenantService } from 'src/app/core/services/tenant.service';
import { ActivatedRoute, Router } from '@angular/router';
import { Tenant } from '../model';
import FormHelper from 'src/app/core/helper/form';
import { Observable } from 'rxjs';
import { ToastService } from 'src/app/core/services/toast.service';


enum MODE {
	edit,
	create
}


@Component({
	selector: 'app-tenant-edit',
	templateUrl: './tenant-edit.component.html',
	styleUrls: ['./tenant-edit.component.scss']
})
export class TenantEditComponent implements OnInit {

	constructor(
		private tenantService: TenantService,
		private route: ActivatedRoute,
		private router: Router,
		public toastService: ToastService,
	) { }


	/** Formulaire current mode */
	mode: MODE = MODE.edit;
	tab: string = "general";

	modes = MODE
	

	tenantId: string
	tenantForm: FormGroup

	formSubmited: boolean = false


	ngOnInit(): void {

		this.initForm()

		if (this.router.url === "/tenants/new") {
			this.mode = MODE.create;

		} else { // If environment update
			this.mode = MODE.edit;

			// Initialise screen
			this.route.params.subscribe(params => {
				this.tenantId = params["tenant_id"];

				this.tenantService
					.getTenant(this.tenantId)
					.subscribe(property => {
						this.objectToForm(property)
					})
			});
		}
	}


	objectToForm(property: Tenant ): void  {
		var formPatch = JSON.parse(JSON.stringify(property));
		this.tenantForm.patchValue(formPatch)
	}

	formToObject(): Tenant {
		let tenant: Tenant = Object.assign({}, this.tenantForm.value);
		tenant.id = this.tenantId
		return tenant
	}


	onSubmit(): void {

		this.formSubmited = true

		if (this.tenantForm.valid) {

			let tenant = this.formToObject()
		
			let save$ : Observable<Tenant>
			if (tenant.id) {
				save$ = this.tenantService.updateTenant(tenant)
			} else {
				save$ = this.tenantService.saveTenant(tenant)
			}

			save$.subscribe(
				tenant => { 
					this.tenantId = tenant.id
					this.toastService.show('The tenant has been saved', { classname: 'alert alert-primary', delay: 5000 }); 
				},
				error => { this.toastService.show('A error occured while saving tenant', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
			console.log(this.tenantService)
			console.log("Invalid form");
		}
	}


	selectTab(tab: string): void {
		this.tab = tab
	}


	initForm() {
		this.tenantForm = new FormGroup({
			'reference':  new FormControl( null, []),
			'type': new FormControl( null, []),
			'personalDetail': new FormGroup({
				'title': new FormControl( null, []),
				'firstName': new FormControl( null, [Validators.required]),
				'middleName': new FormControl( null, []),
				'lastName': new FormControl( null, [Validators.required]),
				'birthDate': new FormControl( null, []),
				'placeOfBirth': new FormControl( null, []),
				'photo': new FormControl( null, [])
			}),
			'proofOfIdentity': new FormGroup({
				'type': new FormControl( null, []),
				'number': new FormControl( null, []),
				'expiry': new FormControl( null, []),
				'file': new FormControl( null, [])
			}),
			'contact': new FormGroup({
				'email': new FormControl( null, []),
				'invitation': new FormControl( null, []),
				'secondaryEmail': new FormControl( null, []),
				'phone': new FormControl( null, []),
				'mobile': new FormControl( null, [])
			}),
			'address': new FormGroup({
				'address1': new FormControl( null, []),
				'address2': new FormControl( null, []),
				'city': new FormControl( null, []),
				'postCode': new FormControl( null, []),
				'region': new FormControl( null, []),
				'country': new FormControl( null, [])
			}),
			'companyInfo': new FormGroup({
				'company': new FormControl( null, []),
				'vatNumber': new FormControl( null, []),
				'companyRegistrationNumber': new FormControl( null, []),
				'profession': new FormControl( null, [])
			}),
			'jobStatus': new FormGroup({
				'occupation': new FormControl( null, []),
				'revenues': new FormControl( null, []),
				'jobStatus': new FormControl( null, [])
			}),
			'professionalAddress': new FormGroup({
				'employer': new FormControl( null, []),
				'address1': new FormControl( null, []),
				'city': new FormControl( null, []),
				'postCode': new FormControl( null, []),
				'region': new FormControl( null, []),
				'country': new FormControl( null, [])
			}),
			'additionalInfo': new FormGroup({
				'comment': new FormControl( null, []),
				'newAddress': new FormControl( null, [])
			}),
			'bankDetails': new FormGroup({
				'bank': new FormControl( null, []),
				'address': new FormControl( null, []),
				'city': new FormControl( null, []),
				'postCode': new FormControl( null, []),
				'country': new FormControl( null, []),
				'branchCode': new FormControl( null, []),
				'sortCode': new FormControl( null, []),
				'bankAccountNumber': new FormControl( null, []),
				'ribKey': new FormControl( null, []),
				'iban': new FormControl( null, []),
				'swiftBic': new FormControl( null, [])
			})/*,
			'guarantors': new FormGroup({
				'type': new FormControl( null, []),
				'name': new FormControl( null, []),
				'surname': new FormControl( null, []),
				'email': new FormControl( null, []),
				'phone': new FormControl( null, []),
				'address': new FormControl( null, []),
				'city': new FormControl( null, []),
				'postCode': new FormControl( null, []),
				'country': new FormControl( null, []),
				'comment': new FormControl( null, [])
			}),
			'documents': new FormGroup({
				'type': new FormControl( null, []),
				'sharing': new FormControl( null, []),
				'description': new FormControl( null, []),
				'file': new FormControl( null, [])
			})*/

		})
	}
}