import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tenancy } from '../../model';
import { TenancyService } from 'src/app/core/services/tenancie.service';
import { ToastService } from 'src/app/core/services/toast.service';
import { InvoiceService } from 'src/app/core/services/invoice.service';

@Component({
  selector: 'app-tenancy-view',
  templateUrl: './tenancy-view.component.html',
  styleUrls: ['./tenancy-view.component.scss', './../../../../styles/entity-view.scss']
})
export class TenancyViewComponent implements OnInit {

  _tenancy: Tenancy

  @Output()
  delete = new EventEmitter();

  constructor(
    private tenancyService: TenancyService,
    private invoiceService: InvoiceService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {
  }

  @Input()
  set tenancy(tenancy: Tenancy) {
    this._tenancy = tenancy
    this.load()
  }

  get tenancy(): Tenancy {
    return this._tenancy
  }

  load() {
  }


  remove(id: string) {
    // TODO confirmation
    this.tenancyService.deleteTenancy(id).subscribe(
      _ => {
        this.delete.emit(id)
      },
      err => {
        this.toastService.show('A error occured while removing tenancy', { classname: 'alert alert-danger', delay: 5000 });
      })
  }


  generateInvoices(): void {
  
    this.invoiceService.generateTenancyInvoices(this._tenancy.id).subscribe(
      _ => {
        this.toastService.show('Invoices have been generated', { classname: 'alert alert-danger', delay: 5000 });
      },
      err => {
        this.toastService.show('A error occured while removing tenancy', { classname: 'alert alert-danger', delay: 5000 });
      })
  }

}
