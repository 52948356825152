import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PropertyService } from 'src/app/core/services/property.service';
import { Property } from 'src/app/properties/properties/model';
import { ActivatedRoute, Router } from '@angular/router';
import FormHelper from 'src/app/core/helper/form';
import { ToastService } from 'src/app/core/services/toast.service';
import { Observable, of } from 'rxjs';
import { map, flatMap } from 'rxjs/operators';

enum MODE {
	edit,
	create
}


@Component({
	selector: 'app-property-edit',
	templateUrl: './property-edit.component.html',
	styleUrls: ['./property-edit.component.scss']
})
export class PropertyEditComponent implements OnInit {

	constructor(
		private toastService: ToastService,
		private propertyService: PropertyService,
		private route: ActivatedRoute,
		private router: Router,
	) { }


	modes = MODE
	
	/** Formulaire current mode */
	mode: MODE = MODE.edit;

	tab: string = "general";

	propertyId: string
	propertyForm: FormGroup

	formSubmited: boolean = false

	ngOnInit(): void {

		this.initForm()

		if (this.router.url === "/properties/new") {
			this.mode = MODE.create;

		} else { // If environment update
			this.mode = MODE.edit;

			// Initialise screen
			this.route.params.subscribe(params => {

				this.propertyId = params["property_id"];

				this.propertyService
					.getProperty(this.propertyId)
					.subscribe(property => {
						this.objectToForm(property)
					})
			});
		}
	}

	objectToForm(property: Property ): void  {
		var formPatch = JSON.parse(JSON.stringify(property));
		this.propertyForm.patchValue(formPatch)
	}

	formToObject(): Observable<Property> {

		let property$ : Observable<Property> = of(new Property())
		
		if(this.propertyId) {
			property$ = this.propertyService.getProperty(this.propertyId)
		}

		return property$.pipe(
			map(property => {

				property =  Object.assign(property, this.propertyForm.value);
				property.description.size = FormHelper.getInValue( this.propertyForm, "description.size" )
				property.tenancyInformation.charges =  FormHelper.getInValue( this.propertyForm, "tenancyInformation.charges")
				property.tenancyInformation.rent =  FormHelper.getInValue( this.propertyForm, "tenancyInformation.rent")
				property.financialInfo.councilTax = FormHelper.getInValue( this.propertyForm,"financialInfo.councilTax")
				property.financialInfo.propertyTax = FormHelper.getInValue( this.propertyForm,"financialInfo.propertyTax")
				property.financialInfo.propertyPurchasePrice = FormHelper.getInValue( this.propertyForm,"financialInfo.propertyPurchasePrice")
				property.financialInfo.acquisitionFees = FormHelper.getInValue( this.propertyForm, "financialInfo.acquisitionFees")
				property.financialInfo.dateAcquired = FormHelper.getDateValue( this.propertyForm,  "financialInfo.dateAcquired")
				property.id = this.propertyId

				return property
			})
		)

	}

	onSubmit(): void {

		this.formSubmited = true

		if (this.propertyForm.valid) {

			let property$ = this.formToObject()

			if (this.propertyId) {
				property$ = property$.pipe(flatMap( p => this.propertyService.updateProperty(p)))
			} else {
				property$ = property$.pipe(flatMap( p => this.propertyService.saveProperty(p)))
			}
			property$.subscribe(
				_ => { this.toastService.show('The property has been saved', { classname: 'alert alert-primary', delay: 5000 }); },
				error => { this.toastService.show('A error occured while saving property', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
			this.toastService.show('Invalid form ', { classname: 'alert alert-danger', delay: 5000 });
		}
	}


	selectTab(tab: string): void {
		this.tab = tab
	}


	initForm() {

		this.propertyForm = new FormGroup({
			'id': new FormControl(),
			'reference': new FormControl(null, [Validators.required]),
			'type': new FormControl(null, []),
			'status': new FormControl(null, []),
			'address': new FormGroup({
				'address1': new FormControl(null, []),
				'address2': new FormControl(null, []),
				'building': new FormControl(null, []),
				'entrance': new FormControl(null, []),
				'floor': new FormControl(null, []),
				'number': new FormControl(null, []),
				'city': new FormControl(null, [Validators.required]),
				'postCode': new FormControl(null, [Validators.required]),
				'country': new FormControl(null, [Validators.required])
			}),
			'description': new FormGroup({
				'size': new FormControl(null, [Validators.min(0)]),
				'numberOfRooms': new FormControl(null, []),
				'numberOfBedrooms': new FormControl(null, []),
				'bathrooms': new FormControl(null, []),
				'yearOfConstruction': new FormControl(null, []),
				'description': new FormControl(null, []),
				'notes': new FormControl(null, [])
			}),
			'additionalInfo': new FormGroup({
				'typeOfHome': new FormControl(null, []),
				'buildingClassification': new FormControl(null, []),
				'furnished': new FormControl(null, []),
				'smokersAllowed': new FormControl(null, []),
				'petsAllowed': new FormControl(null, []),
				'furniture': new FormGroup({
					'internetAccess': new FormControl(null, []),
					'playground': new FormControl(null, []),
					'elevador': new FormControl(null, []),
					'barbecue': new FormControl(null, []),
					'basement': new FormControl(null, []),
					'sportsClub': new FormControl(null, []),
					'parksAndGardens': new FormControl(null, []),
					'garage': new FormControl(null, []),
					'doorman': new FormControl(null, []),
					'garden': new FormControl(null, []),
					'parking': new FormControl(null, []),
					'tennis': new FormControl(null, []),
					'garbageDisposer': new FormControl(null, []),
					'airConditioning': new FormControl(null, []),
					'satelliteTV': new FormControl(null, []),
					'balcony': new FormControl(null, []),
					'cable_Fiber': new FormControl(null, []),
					'communalHeating': new FormControl(null, []),
					'collectiveHotWater': new FormControl(null, []),
					'gym': new FormControl(null, []),
					'bikeStorage': new FormControl(null, []),
					'intercom': new FormControl(null, []),
					'laundry': new FormControl(null, []),
					'pool': new FormControl(null, []),
					'remoteSurveillance': new FormControl(null, []),
					'patio': new FormControl(null, [])
				}),
				'parking': new FormControl(null, []),
				'otherAnnexes': new FormControl(null, []),
				'basement': new FormControl(null, []),
				'lot': new FormControl(null, []),
				'proportion': new FormControl(null, [])
			}),
			'landRegistry': new FormGroup({
				'plan': new FormControl(null, []),
				'parcel': new FormControl(null, []),
				'category': new FormControl(null, []),
				'tenancyValue': new FormControl(null, [])
			}),
			'tenancyInformation': new FormGroup({
				'type': new FormControl(null, []),
				'rent': new FormControl(null, []),
				'charges': new FormControl(null, [])
			}),
			'financialInfo': new FormGroup({
				'councilTax': new FormControl(null, []),
				'propertyTax': new FormControl(null, []),
				'dateAcquired': new FormControl(null, []),
				'propertyPurchasePrice': new FormControl(null, []),
				'acquisitionFees': new FormControl(null, [])
			}),
			'taxOffice': new FormGroup({
				'officeName': new FormControl(null, []),
				'address1': new FormControl(null, []),
				'address2': new FormControl(null, []),
				'postCode': new FormControl(null, []),
				'city': new FormControl(null, []),
				'notes': new FormControl(null, [])
			})/*,
			'contracts': new FormGroup({
				'type': new FormControl(null, []),
				'file': new FormControl(null, []),
				'description': new FormControl(null, []),
				'dateOfIssue': new FormControl(null, []),
				'expiryDate': new FormControl(null, [])
			})/*,
			'photos': new FormGroup({
				'file': new FormControl(null, [])
			}),
			'documents': new FormGroup({
				'type': new FormControl(null, []),
				'sharing': new FormControl(null, []),
				'description': new FormControl(null, []),
				'file': new FormControl(null, [])
			})*/
		})
	}
}



