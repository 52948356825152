import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { DashboardComponent } from './dashdoard/dashboard/dashboard.component';
import { PropertiesListComponent } from './properties/properties/properties-list/properties-list.component';
import { TenantsListComponent } from './tenants/tenants-list/tenants-list.component';
import { TenanciesListComponent } from './tenancies/tenancies-list/tenancies-list.component';
import { FunishingsListComponent } from './furnishings/funishings-list/funishings-list.component';
import { InventoriesListComponent } from './inventories/inventories-list/inventories-list.component';;
import { PropertyEditComponent } from './properties/properties/property-edit/property-edit.component';
import { TenantEditComponent } from './tenants/tenant-edit/tenant-edit.component';
import { TenancyEditComponent } from './tenancies/tenancy-edit/tenancy-edit.component';

import { FurnishingEditComponent } from './furnishings/furnishing-edit/furnishing-edit.component';
import { InventoryEditComponent } from './inventories/inventory-edit/inventory-edit.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { SignupComponent } from './authentication/signup/signup.component';
import { SigninComponent } from './authentication/signin/signin.component';

import { InvoicesListComponent } from './financies/invoices/invoices-list/invoices-list.component';
import { InvoiceEditComponent } from './financies/invoices/invoice-edit/invoice-edit.component';
import { TicketEditComponent } from './financies/tickets/ticket-edit/ticket-edit.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { SettingsLayoutComponent } from './layouts/settings-layout/settings-layout.component';
import { TransactionsListComponent } from './financies/transactions/transactions-list/transactions-list.component';


const routes: Routes = [

  { path: 'signin', component: SigninComponent },
  { path: 'signup', component: SignupComponent },
  
  {path: 'settings', pathMatch: 'full', redirectTo: 'settings/profile'},
  { 
    path: 'settings',
    component: SettingsLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'profile', component: ProfileComponent}
    ]
  },

  {path: '', pathMatch: 'full', redirectTo: 'dashboard'},
  {
    path: '',
    component: AppLayoutComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'dashboard', component: DashboardComponent},

      { path: 'properties', component: PropertiesListComponent},
      { path: 'properties/new', component: PropertyEditComponent},
      { path: "properties/:property_id/edit", component: PropertyEditComponent},
      
      { path: 'tenants', component: TenantsListComponent},
      { path: 'tenants/new', component: TenantEditComponent},
      { path: "tenants/:tenant_id/edit", component: TenantEditComponent},

      { path: 'tenancies', component: TenanciesListComponent},
      { path: 'tenancies/new', component: TenancyEditComponent},
      { path: "tenancies/:tenancy_id/edit", component: TenancyEditComponent},

      { path: 'financies', redirectTo: 'financies/invoices'},
      { path: 'financies/invoices', component: InvoicesListComponent},
      { path: 'financies/invoices/:invoice_id/edit', component: InvoiceEditComponent},
      { path: 'financies/invoices/:invoice_id/tickets/edit', component: TicketEditComponent},

      { path: 'financies/transactions', component: TransactionsListComponent},

      { path: 'furnishings', component: FunishingsListComponent},
      { path: 'furnishings/new', component: FurnishingEditComponent},

      { path: 'inventories', component: InventoriesListComponent},
      { path: 'inventories/new', component: InventoryEditComponent},
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { 


}
