import { Component, OnInit } from '@angular/core';
import { Invoice } from '../model';
import { InvoiceService } from 'src/app/core/services/invoice.service';
import * as moment from 'moment'
import { DownloadUtils } from 'src/app/core/helper/download';
import { Router } from '@angular/router';
import { RemoveConfimModalComponent } from '../../../core/components/remove-confim-modal/remove-confim-modal.component';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';


@Component({
  selector: 'app-invoices-list',
  templateUrl: './invoices-list.component.html',
  styleUrls: ['./invoices-list.component.scss']
})
export class InvoicesListComponent implements OnInit {


  constructor(
    private invoiceService: InvoiceService,
    private modalService: NgbModal,
    private router: Router) { }

  protected selectedInvoiceId: string = null
  protected isEdit: boolean = false
  public invoices: Invoice[]

  ngOnInit() {
    this.loadTable()
  }

  loadTable() {
    this.invoiceService.search({}).subscribe(invoices => {
      this.invoices = invoices
    })
  }

  findInvoiceById(invoiceId: string): Invoice {
    var filteredInvoices = this.invoices.filter(t => t.id == invoiceId)
    if (filteredInvoices.length >= 1) {
      return filteredInvoices[0]
    }
    return null
  }

  // Provide A PDF file name for the given invoice
  getTicketName(invoiceId: string): string {
    var res = "ticket.pdf"

    // get the selected invoices
    var invoice = this.findInvoiceById(invoiceId)

    // generate invoice ticket name
    if (invoice) {
      res = "ticket"
      // Add ticket property reference in name
      if (invoice.property && invoice.property.reference) {
        res += "-" + invoice.property.reference
      }
      // Add date to the ticket
      if (invoice.date) {
        var momentDate = moment(invoice.date)
        res += momentDate.format("-MM-YYYY")
      }
      res += ".pdf"
    }
    return res
  }

  // Open a modal for the invoice edition
  edit(invoiceId: string) {
    this.selectedInvoiceId = invoiceId
    this.isEdit = !this.isEdit
  }

  editTicket(invoiceId: string) {
    this.router.navigate(['financies', 'invoices', invoiceId, 'tickets', 'edit'])
  }

  finishEdit(){
    this.isEdit = false
    this.loadTable()
  }
  
  onSideWindowChange(isOpened: boolean) {
    this.isEdit = isOpened
  }

  // Remove the given invoice
  remove(invoiceId: string) {

    const modalRef = this.modalService.open(RemoveConfimModalComponent, {});
    modalRef.componentInstance.entity = "Invoice"

    let invoice = this.findInvoiceById(invoiceId)
    if (invoice && invoice.month && invoice.year) {
            
        let strYear = (""+invoice.year).padStart(2, "0")
        let strMonth = (""+invoice.month).padStart(2, "0")

        modalRef.componentInstance.entity = "the invoice for "+strMonth +"/"+ strYear  
    }

    modalRef.result.then(confirmed => {

      // remove only if user confirm
      if (confirmed) {    
          this.invoiceService
          .deleteInvoice(invoiceId)
          .subscribe(() => {
            this.loadTable()
          }, err => {
            //TODO error handler
           })
      }

    }).catch((error) => {
      console.log(error);
    });


  }

  // Generate and Dowload PDF ticket for the given invoice ID
  generateTicket(invoiceId: string) {
    this.invoiceService.downloadPDFTicket(invoiceId).subscribe(data => {

      let options = { type: 'application/pdf' };
      DownloadUtils.createAndDownloadBlobFile(data, options, this.getTicketName(invoiceId));
    });
  }

  // Sort the invoice table
  // TODO: 
  onSort(event) {

  }
}
