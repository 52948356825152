import { Component, OnInit, Input } from '@angular/core';
import { Tenant } from '../../model';

@Component({
  selector: 'app-tenant-line',
  templateUrl: './tenant-line.component.html',
  styleUrls: ['./tenant-line.component.scss']
})
export class TenantLineComponent implements OnInit {

  @Input()
  tenant: Tenant

  @Input()
  active: Boolean

  constructor() { }

  ngOnInit() {
  }

  isActive(): Boolean{
    return this.active;
  }
}
