import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Tenant, Address } from '../../model';
import { TenantService } from 'src/app/core/services/tenant.service';
import { ToastService } from 'src/app/core/services/toast.service';
;

@Component({
  selector: 'app-tenant-view',
  templateUrl: './tenant-view.component.html',
  styleUrls: ['./tenant-view.component.scss',
               './../../../../styles/entity-view.scss']
})
export class TenantViewComponent implements OnInit {

  _tenant: Tenant

  @Output()
  delete = new EventEmitter();

  constructor(
    private tenantService: TenantService,
    private toastService: ToastService
  ) { }

  ngOnInit() {
  }

  @Input()
  set tenant(tenant: Tenant) {
    this._tenant = tenant
    this.load()
  }

  get tenant(): Tenant {
    return this._tenant
  }

  load() {
  }

  isAddressEmpty(address: Address) :boolean{
    if(address != null){
      return !(address.address1 || address.address2 || address.city || address.postCode|| address.region ||address.country)
    }
    return true
  }
  remove(id: string) {
    this.tenantService.deleteTenant(id).subscribe(
      _ => {
        this.delete.emit(id)
      },
      err => {
        this.toastService.show('A error occured while removing tenant', { classname: 'alert alert-danger', delay: 5000 });
      })
  }

}
