import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastService } from '../../core/services/toast.service';

@Component({
  selector: 'app-inventory-edit',
  templateUrl: './inventory-edit.component.html',
  styleUrls: ['./inventory-edit.component.scss']
})
export class InventoryEditComponent implements OnInit {

  constructor(
    private toastService: ToastService,
  ) { }

  tab: string = "general";

  inventoryForm: FormGroup

  formSubmited: boolean = false

  ngOnInit(): void {
    this.initForm()
  }
  
  onSubmit(): void {
    console.log("Fform submitted ");
    this.formSubmited = true 

    if( this.inventoryForm.valid ) {
      console.log(this.inventoryForm.value); 
    }else{
      this.toastService.show('Invalid formulaire data', { classname: 'alert alert-danger', delay: 3000 });
    }
  }


  selectTab(tab: string): void{
    this.tab = tab
  }

  initForm(){

      this.inventoryForm =   new FormGroup({ 
        'type': new FormControl('', [ ]) ,
        'id': new FormControl('', [ ]) ,
        'tenancy': new FormControl('', [ ]) ,
        'signatories':  new FormGroup({ 
          'note': new FormControl('', [ ])
        })
    })
  }
}