import { Injectable } from '@angular/core';
import { ApiService, GetOpts } from './api.service';
import { Observable } from 'rxjs';
import { Ticket, TicketCriteria } from 'src/app/financies/tickets/model';


@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private api: ApiService) {}

  search(criteria: TicketCriteria): Observable<Ticket[]> {

    var params : string[] = []


    if(criteria.ticketIds){
      params.push("ids="+criteria.ticketIds.join(","))
    }
    
    if(criteria.invoiceIds){
      params.push("invoicesIds="+criteria.invoiceIds.join(","))
    }

    if(criteria.tenantId){
      params.push("tenantId="+criteria.tenantId)
    }

    if(criteria.tenancyId){
      params.push("tenancyId="+criteria.tenancyId)
    }

    if(criteria.propertyId){
      params.push("propertyId="+criteria.propertyId)
    }

    return this.api.getJson(`${this.api.url}/tickets?${params.join("&")}`, {allow404: true, defaultIfNull:[] });
  }

  getTicket(id: string, opts: GetOpts = {}): Observable<Ticket> {
    return this.api.getJson(`${this.api.url}/tickets/${id}`, opts);
  }

  updateTicket(ticket: Ticket): Observable<Ticket> {
    return this.api.putJson(`${this.api.url}/tickets/${ticket.id}`, ticket);
  }

  saveTicket(ticket: Ticket): Observable<Ticket> {
    return this.api.postJson(`${this.api.url}/tickets`, ticket);
  }

  deleteTicket(id: string): Observable<void> {
    return this.api.delete(`${this.api.url}/tickets/${id}`);
  }

}
