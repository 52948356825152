import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';
import { ToastService } from 'src/app/core/services/toast.service';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'src/app/core/services/authentication.service';
import { User } from 'src/app/core/models/user';
import FormHelper from 'src/app/core/helper/form';
import { FormMapper } from './profile.mapper';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss']
})
export class ProfileComponent implements OnInit {

  protected formSubmited: boolean = false
  public status: string = "LOADING"
  public form: FormGroup;

  protected userId: string;
  protected user: User;

  protected formMapper: FormMapper  = new FormMapper()

  constructor(    
    private userService: UserService,
    private toastService: ToastService,
  ) { }

  ngOnInit() {

    // get current user
    this.userService.getMe()
      .subscribe( user => {
        this.user = user
        this.userId = user.id
        this.form = this.formMapper.initForm()

        // Load form with entity fields
        this.formMapper.loadForm(this.form, this.user)

        this.status = "LOADED" 
      })

  }


  onSubmit(){

    this.formSubmited = true

		if (this.form.valid) {

			let userUpdated = this.formMapper.loadModel(this.userId, this.user, this.form)

			let save$ =this.userService.updateUser(userUpdated)

			save$.subscribe(
				_ => {  },
				error => { this.toastService.show('A error occured while saving invoice', { classname: 'alert alert-danger', delay: 5000 }); }
			)

		} else {
			console.log(this.form)
			console.log("Invalid form");
		}
  }

}


