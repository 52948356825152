import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http'
//import { ErrorInterceptor } from './interceptors/ErrorInterceptor';



import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { ChartsModule } from 'ng2-charts';

import {NgbModule, NgbDateParserFormatter, NgbDateAdapter} from '@ng-bootstrap/ng-bootstrap';

import { PropertiesListComponent } from './properties/properties/properties-list/properties-list.component';
import { DashboardComponent } from './dashdoard/dashboard/dashboard.component';
import { AppLayoutComponent } from './layouts/app-layout/app-layout.component';
import { TenantsListComponent } from './tenants/tenants-list/tenants-list.component';
import { TenanciesListComponent } from './tenancies/tenancies-list/tenancies-list.component';
import { FunishingsListComponent } from './furnishings/funishings-list/funishings-list.component';
import { InventoriesListComponent } from './inventories/inventories-list/inventories-list.component';
import { PropertyEditComponent } from './properties/properties/property-edit/property-edit.component';
import { PropertyGeneralTabComponent } from './properties/properties/property-edit/tabs/property-general-tab/property-general-tab.component';
import { PropertyAdditionalTabComponent } from './properties/properties/property-edit/tabs/property-additional-tab/property-additional-tab.component';
import { PropertyDocumentsTabComponent } from './properties/properties/property-edit/tabs/property-documents-tab/property-documents-tab.component';
import { PropertyContractsTabComponent } from './properties/properties/property-edit/tabs/property-contracts-tab/property-contracts-tab.component';
import { PropertyPhotosTabComponent } from './properties/properties/property-edit/tabs/property-photos-tab/property-photos-tab.component';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { TenantEditComponent } from './tenants/tenant-edit/tenant-edit.component';
import { TenancyEditComponent } from './tenancies/tenancy-edit/tenancy-edit.component';
import { FurnishingEditComponent } from './furnishings/furnishing-edit/furnishing-edit.component';
import { FurnishingGeneralTabComponent } from './furnishings/furnishing-edit/tabs/furnishing-general-tab/furnishing-general-tab.component';
import { TenancyGeneralTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-general-tab/tenancy-general-tab.component';
import { TenancyAdditionalTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-additional-tab/tenancy-additional-tab.component';
import { TenancyReceiptsTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-receipts-tab/tenancy-receipts-tab.component';
import { TenancyOtherTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-other-tab/tenancy-other-tab.component';
import { TenancyInsuranceTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-insurance-tab/tenancy-insurance-tab.component';
import { TenancyDocumentsTabComponent } from './tenancies/tenancy-edit/tabs/tenancy-documents-tab/tenancy-documents-tab.component';
import { TenantGeneralTabComponent } from './tenants/tenant-edit/tabs/tenant-general-tab/tenant-general-tab.component';
import { TenantAdditionalTabComponent } from './tenants/tenant-edit/tabs/tenant-additional-tab/tenant-additional-tab.component';
import { TenantGuarantorsTabComponent } from './tenants/tenant-edit/tabs/tenant-guarantors-tab/tenant-guarantors-tab.component';
import { TenantDocumentsTabComponent } from './tenants/tenant-edit/tabs/tenant-documents-tab/tenant-documents-tab.component';
import { InventoryEditComponent } from './inventories/inventory-edit/inventory-edit.component';
import { InventorySignatoriesTabComponent } from './inventories/inventory-edit/tabs/inventory-signatories-tab/inventory-signatories-tab.component';
import { InventoryGeneralTabComponent } from './inventories/inventory-edit/tabs/inventory-general-tab/inventory-general-tab.component';
import { FurnishingSignatoriesTabComponent } from './furnishings/furnishing-edit/tabs/furnishing-signatories-tab/furnishing-signatories-tab.component';
import { ShowErrorsComponent } from './core/components/show-errors.component';
import { PropertyService } from './core/services/property.service';
import { ApiService } from './core/services/api.service';
import { AddPhotoModalComponent } from './core/components/add-photo-modal/add-photo-modal.component';
import { AddDocumentModalComponent } from './core/components/add-document-modal/add-document-modal.component';
import { TagComponentComponent } from './core/components/tag-component/tag-component.component';
import { TenantTypeSelectorComponentComponent } from './core/components/tenant-type-selector-component/tenant-type-selector-component.component';
import { TenancyService } from './core/services/tenancie.service';
import { TenantService } from './core/services/tenant.service';
import { AddTenantModalComponent } from './tenancies/tenancy-edit/modals/add-tenant-modal/add-tenant-modal.component';
import { ToastService } from './core/services/toast.service';
import { ToastsContainer } from './core/components/toast-container-component/toast-container.component';
import { CustomDateParserFormatter, CustomAdapter } from './core/date';

import { AddContratModalComponent } from './properties/properties/property-edit/modals/add-contrat-modal/add-contrat-modal.component';
import { TopbarProfileComponent } from './core/components/topbar-profile/topbar-profile.component';
import { SigninComponent } from './authentication/signin/signin.component';
import { AuthGuardService } from './core/services/auth-guard.service';
import { AuthenticationService } from './core/services/authentication.service';
import { ConfigurationService } from './core/services/configuration.service';
import { SignupComponent } from './authentication/signup/signup.component';
import { AccountService } from './core/services/account.service';
import { PropertyLineComponent } from './properties/properties/properties-list/property-line/property-line.component';
import { PropertyViewComponent } from './properties/properties/properties-list/property-view/property-view.component';
import { ArrangementViewComponent } from './core/components/arrangement-view/arrangement-view.component';
import { PropertyTenancyViewComponent } from './properties/properties/properties-list/property-view/tenancy-view/tenancy-view.component';
import { PropertyTypeIconComponent } from './properties/properties/property-type-icon/property-type-icon.component';

// Tenants
import { TenancyLineComponent } from './tenancies/tenancies-list/tenancy-line/tenancy-line.component';
import { TenancyViewComponent } from './tenancies/tenancies-list/tenancy-view/tenancy-view.component';
import { TenantViewComponent } from './tenants/tenants-list/tenant-view/tenant-view.component';
import { TenantLineComponent } from './tenants/tenants-list/tenant-line/tenant-line.component';
import { TenantTypeIconComponent } from './tenants/tenant-type-icon/tenant-type-icon.component';
import { TenancyTypeIconComponent } from './tenancies/tenancy-type-icon/tenancy-type-icon.component';
import { BankDetailViewerComponent } from './tenants/tenants-list/tenant-view/bank-detail-viewer/bank-detail-viewer.component';

// Tenants components
import { TenantsAvatarsViewerComponent } from './core/components/tenant/tenants-avatars-viewer/tenants-avatars-viewer.component';
import { TenantsInfoAvatarsViewerComponent } from './core/components/tenant/tenants-info-avatars-viewer/tenants-info-avatars-viewer.component';


// Board wiget
import { FinancesViewerComponent } from './dashdoard/dashboard/widgets/finances-viewer/finances-viewer.component';
import { CreditsViewerComponent } from './dashdoard/dashboard/widgets/credits-viewer/credits-viewer.component';

// invoices modules
import { InvoicesListComponent } from './financies/invoices/invoices-list/invoices-list.component';
import { InvoiceEditComponent } from './financies/invoices/invoice-edit/invoice-edit.component';
import { InvoiceService } from './core/services/invoice.service';

//Invoice component
import { InvoiceStatusViewerComponent } from './core/components/invoice/invoice-status-viewer/invoice-status-viewer.component';
import { TicketService } from './core/services/ticket.service';
import { TicketEditComponent } from './financies/tickets/ticket-edit/ticket-edit.component';
import { SideWindowComponent } from './core/components/layout/side-window/side-window.component';
import { ProfileComponent } from './settings/profile/profile.component';
import { SettingsLayoutComponent } from './layouts/settings-layout/settings-layout.component';
import { UserService } from './core/services/user.service';
import { TransactionsListComponent } from './financies/transactions/transactions-list/transactions-list.component';
import { TransactionService } from './core/services/transaction.service';
import { TransactionEditComponent } from './financies/transactions/transaction-edit/transaction-edit.component';
import { RemoveConfimModalComponent } from './core/components/remove-confim-modal/remove-confim-modal.component';
import { TopBarComponent } from './core/components/layout/top-bar/top-bar.component';
import { CardLabelComponent } from './core/components/card-label/card-label.component';
//////

@NgModule({
  declarations: [
    AppComponent,
    AppLayoutComponent,

    ShowErrorsComponent,

    DashboardComponent,

    PropertiesListComponent,
    PropertyEditComponent,
    PropertyGeneralTabComponent,
    PropertyAdditionalTabComponent,
    PropertyDocumentsTabComponent,
    PropertyContractsTabComponent,
    PropertyPhotosTabComponent,
    AddContratModalComponent,

    TenantsListComponent,
    TenantEditComponent,
    TenantGeneralTabComponent,
    TenantAdditionalTabComponent,
    TenantGuarantorsTabComponent,
    TenantDocumentsTabComponent,


    TenanciesListComponent,
    TenancyEditComponent,
    TenancyGeneralTabComponent,
    TenancyAdditionalTabComponent,
    TenancyReceiptsTabComponent,
    TenancyOtherTabComponent,
    TenancyInsuranceTabComponent,
    TenancyDocumentsTabComponent,

    InventoriesListComponent,
    InventoryEditComponent,
    InventorySignatoriesTabComponent,
    InventoryGeneralTabComponent,

    FunishingsListComponent,
    FurnishingEditComponent,
    FurnishingGeneralTabComponent,
    FurnishingSignatoriesTabComponent,

    AddPhotoModalComponent,
    AddDocumentModalComponent,

    TagComponentComponent,
    TenantTypeSelectorComponentComponent,
    AddTenantModalComponent,


    
    ToastsContainer,

    TopbarProfileComponent,

    SigninComponent,
    SignupComponent,
    PropertyLineComponent,
    PropertyViewComponent,
    ArrangementViewComponent,
    PropertyTenancyViewComponent,
    TenancyViewComponent,
    PropertyTypeIconComponent,
    TenancyLineComponent,
    TenantViewComponent,
    TenantLineComponent,
    TenantTypeIconComponent,
    TenancyTypeIconComponent,
    TenantsAvatarsViewerComponent,
    TenantsInfoAvatarsViewerComponent,
    BankDetailViewerComponent,

    // Bashboard / wodget
    FinancesViewerComponent,
    CreditsViewerComponent,

    // Invoices
    InvoiceEditComponent,
    InvoicesListComponent,
    InvoiceStatusViewerComponent,

    // Tickets 
    TicketEditComponent,

    SideWindowComponent,

    ProfileComponent,

    SettingsLayoutComponent,

    TransactionsListComponent,

    TransactionEditComponent,

    RemoveConfimModalComponent,

    TopBarComponent,

    CardLabelComponent,
    
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ChartsModule,
  ],
  exports: [AddPhotoModalComponent],
  providers: [
    ApiService,
    PropertyService,
    TenantService,
    TenancyService,
    ToastService,
    AuthGuardService,
    AuthenticationService,
    ConfigurationService,
    AccountService,
    InvoiceService,
    TicketService,
    UserService,
    TransactionService,

    {provide: NgbDateAdapter, useClass: CustomAdapter},
    {provide: NgbDateParserFormatter, useClass: CustomDateParserFormatter}


  ],
  entryComponents: [AddPhotoModalComponent, AddTenantModalComponent, AddContratModalComponent, RemoveConfimModalComponent],

  bootstrap: [AppComponent]
})
export class AppModule { }
