import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl } from '@angular/forms';

@Component({
  selector: 'app-furnishing-edit',
  templateUrl: './furnishing-edit.component.html',
  styleUrls: ['./furnishing-edit.component.scss']
})
export class FurnishingEditComponent implements OnInit {

  constructor() { }

  tab: string = "general";

  furnishingForm: FormGroup

  ngOnInit(): void {
    this.initForm()
  }
  
  onSubmit(): void {
    console.log(this.furnishingForm.value); 
  }


  selectTab(tab: string): void{
    this.tab = tab
  }

  initForm(){

    this.furnishingForm  =  new FormGroup({ 
      'id': new FormControl('', [ ]) ,
      'property': new FormControl('', [ ]) ,
      'tenancy': new FormControl('', [ ]) ,
      'signatories':  new FormGroup({ 
        'note': new FormControl('', [ ])
      }) 
    })
  }
}